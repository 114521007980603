import { Button, CardActions, CardContent, DialogActions, FormControl, Grid, Typography } from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Messaggi, NotificationStatus, dialogButtonGeneral } from "../../consts/dictionary";
import { initialValuesPinAccess } from "../../consts/formik/RecuperoSessione/initialValuesRecuperoSessione";
import { validationSchemaAccessPin } from "../../consts/formik/RecuperoSessione/validationSchemaRecuperoSessione";
import AppSettingContext from "../../context/AppSettingsContext";
import "../../index.css";
import { hideLoader, showLoader } from "../../reducers/appLoader-reducer";
import { notificationOpen } from "../../reducers/notification-reducer";
import { enableRenderPermission } from "../../reducers/permission-reducer";
import { isRunningInElectron } from "../../shared/functions/isRanningElectron";
import { Row, Col, Card } from "react-bootstrap";
const NotebookAccessPin = ({ onAccess }) => {

    const appSettings = useContext(AppSettingContext);
    const dispatch = useDispatch();
    const [changedRow, setchangedRow] = useState();
    let initialValues = initialValuesPinAccess();
    let validationSchema = validationSchemaAccessPin;



    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            setchangedRow({
                accessPin: values.accessPin,
            });
            formik.resetForm(initialValues);
        }
    });

    useEffect(() => {
        if (changedRow != null) {
            (async () => {
                dispatch(showLoader())
                let accessPin;
                accessPin = changedRow.accessPin;
                try {
                    var pin = parseInt(+accessPin);
                    await axios.post(`${appSettings.URL_API}/${appSettings.VERIFICA_PIN_ACCESSO}`, { pinAccesso: pin })
                        .then((response) => {
                            if (response.data.ok) {
                                formik.resetForm(initialValues);
                                var result = response.data.result;
                                onAccess(
                                    {
                                        idSala: result.idSala,
                                        qrCodeToken: result.qrCodeToken,
                                        isUtenteInail: isRunningInElectron()
                                    });
                            } else {
                                dispatch(notificationOpen({ message: response.data.message, status: NotificationStatus.Error }));
                            }
                        });
                } catch (error) {
                    console.log('Errore durante l\'invocazione SignalR:', error);
                    dispatch(notificationOpen({ message: Messaggi.ComunicazioneServerAssente, status: NotificationStatus.Error }));
                    dispatch(hideLoader());
                }
                dispatch(hideLoader())
            })();
        }
        dispatch(enableRenderPermission());

    }, [changedRow]);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <FormControl fullWidth >
                    <Row className="row m-0 pt-3">
                        <Col className="">
                            <Card className="PINCard"
                                style={{
                                    width: '25rem',
                                    height:'15rem',
                                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', // Custom box-shadow
                                }}
                            >
                                <Card.Body className="w-100">
                                    <Card.Title className="text-center pb-4 titoloAccessPin-fs1_5"><b className="fontfamily cl-black ">INSERISCI Il PIN</b></Card.Title>
                                    <div className="w-100  text-center px-2">
                                        <input
                                            style={{ height: '35px', textAlign: 'center', fontWeight: 'bold', fontSize:'30px' }}
                                            type="text"
                                            className="cl-black w-50"
                                            value={formik.values.accessPin}
                                            onChange={formik.handleChange("accessPin")}
                                        />
                                        {formik.touched.accessPin && Boolean(formik.errors.accessPin) &&
                                            <Typography mt={1} sx={{ fontSize: '0.9rem', color: 'red', textAlign: 'center' }} color="text.secondary" gutterBottom>
                                                {formik.touched.accessPin && formik.errors.accessPin}
                                            </Typography>}

                                        <DialogActions sx={{ mt: 3 }} className="d-flex justify-content-center">
                                            <Button variant="text" sx={{ color: '#002E5D' }} onClick={() => { formik.resetForm(initialValues) }}>
                                                <span className="fontfamily">{dialogButtonGeneral.Reset}</span>
                                            </Button>
                                            <Button type="submit" variant="contained" className="primaryButton">
                                                <span className="fontfamily" >{dialogButtonGeneral.Invia}</span>
                                            </Button>
                                        </DialogActions>
                                    </div>
                                </Card.Body>
                            </Card>


                            {/* <Card className="PINCard">
                                <CardContent>
                                    <Typography mb={3} sx={{ fontSize: '1rem' }} color="text.secondary" className=" text-center" gutterBottom>
                                        <b className="fontfamily cl-black">Inserisci il PIN </b>
                                    </Typography>
                                    <input
                                        style={{ height: '30px', textAlign: 'center', fontWeight: 'bold' }}
                                        type="text"
                                        className="cl-black"
                                        value={formik.values.accessPin}
                                        onChange={formik.handleChange("accessPin")}
                                    />
                                    {formik.touched.accessPin && Boolean(formik.errors.accessPin) &&
                                        <Typography mt={1} sx={{ fontSize: '0.9rem', color: 'red', textAlign: 'center' }} color="text.secondary" gutterBottom>
                                            {formik.touched.accessPin && formik.errors.accessPin}
                                        </Typography>}
                                </CardContent>
                                <CardActions>
                                    <DialogActions sx={{ mt: 3 }}>
                                        <Button variant="text" sx={{ color: '#002E5D' }} onClick={() => { formik.resetForm(initialValues) }}>
                                            <span className="fontfamily">{dialogButtonGeneral.Reset}</span>
                                        </Button>
                                        <Button type="submit" variant="contained" className="primaryButton">
                                            <span className="fontfamily" >{dialogButtonGeneral.Invia}</span>
                                        </Button>
                                    </DialogActions>
                                </CardActions>
                            </Card> */}
                        </Col>
                    </Row>
                </FormControl>
            </form>
        </>
    );
};
export default NotebookAccessPin;