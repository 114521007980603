import { useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { Grid, Typography, Button } from '@mui/material';
import { hideLoader } from "../../reducers/appLoader-reducer";
import exitImage from '../../assets/uscita_sala.svg';
import "../../index.css";
import { isRunningInElectron } from "../../shared/functions/isRanningElectron";
import isNavigatingFromNotebook from "../../shared/functions/isNavigatingFromNotebook";
import { useNavigate } from "react-router-dom";
import AppSettingContext from "../../context/AppSettingsContext";
import { Row, Col } from "react-bootstrap";
import CustomButton from "../../shared/components/CustomButton";
import { RiavviaService } from "../../shared/services/riavvia.service";


const Exit = ({ message }) => {
    const appSettingContext = useContext(AppSettingContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const backButtonLogic = (oldstate) => { window.history.pushState(oldstate, ""); };
    const service = new RiavviaService(appSettingContext);
    useEffect(() => {
        let oldstate = window.history.state;
        window.history.pushState(oldstate, "");
        window.onpopstate = (event) => {
            if (event) backButtonLogic(oldstate);
        }


    }, []);

    useEffect(() => {
        dispatch(hideLoader());
    }, []);

    return (
        <>
         <Row className="mx-0">
                <Col xs={12} md={12} lg={12}>
                    <Row className="d-flex justify-content-center">
                        <Col xs={12} md={12} lg={12} className="d-flex justify-content-center">
                            <img src={exitImage} alt='' download="" className="imageHalfAutoSize" />
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-center mt-5">
                        <Col xs={12} md={12} lg={12} className="d-flex justify-content-center">
                            <h5 className="boldBlueText fontfamily testo-fs1_2 text-center">Sei uscito dalla sala.</h5>
                        </Col>
                    </Row>
                    {
                        (isRunningInElectron() || isNavigatingFromNotebook()) && (
                            <Row className="d-flex justify-content-center mt-5">
                                <Col xs={12} md={12} lg={12} className="d-flex justify-content-center">
                                    <h5 className="boldBlueText fontfamily testo-fs1_2 text-center"> {message ? message + ' Clicca sul bottone Riavvia per utilizzare l\'applicazione.' : 'Clicca sul bottone Riavvia per utilizzare l\'applicazione.'}</h5>
                                </Col>
                            </Row>
                        )
                    }
                    {
                        (!isRunningInElectron() && !isNavigatingFromNotebook()) && (
                            <Row className="d-flex justify-content-center mt-5">
                                <Col xs={12} md={12} lg={12} className="d-flex justify-content-center">
                                    <h5 className="boldBlueText fontfamily testo-fs1_2 text-center">{message ? message + ' Inquadra nuovamente il QR-code per utilizzare l\'applicazione.' : 'Inquadra nuovamente il QR-code per utilizzare l\'applicazione.'}</h5>
                                </Col>
                            </Row>
                        )
                    }
                    {(isRunningInElectron() || isNavigatingFromNotebook()) && (
                        <Row className="d-flex justify-content-center mt-5 w-100">
                            <Col className="d-flex justify-content-center" xs={12} md={12} lg={12}>
                                <CustomButton classText="btn-text-size " size="" text="Riavvia" className={isNavigatingFromNotebook() ? 'w-50' : 'w-40'} onClickFn={async () => service.handleRiavvia()}></CustomButton>
                            </Col>

                        </Row>
                    )}
                </Col>
            </Row>
            </>
    )
}

export default Exit;