import { useOrientation } from "react-use";
import { isRunningInElectron } from "../shared/functions/isRanningElectron";

const useCustomOrientation = () => {
    const { angle, type } = useOrientation();
    return isRunningInElectron() ? {angle:0, type: "portrait-primary"} : { angle, type };


};

export default useCustomOrientation;
