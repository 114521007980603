import { useContext, useEffect, useState } from "react";
import { Grid } from '@mui/material'
import "../../index.css";
import { HubEndpoint, Messaggi, NotificationStatus, OperazioneSceltaMobile } from '../../consts/dictionary';
import { showLoader, hideLoader } from "../../reducers/appLoader-reducer";
import ConnectionContext from "../../context/ConnectionContext";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { notificationOpen } from '../../reducers/notification-reducer';
import Notification from '../../components/Notification';
import { Box } from "@mui/system";
import isNavigatingFromNotebook from "../../shared/functions/isNavigatingFromNotebook";


export const IOS = 'iOs';

export const IPHONE = 'iPhone';
export const IPAD = 'iPad';
export const ANDROID = 'Android';

const Teletabber = ({ teletabberUrl }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isFromIphone, setisFromIphone] = useState();
    const [isFromIpad, setisFromIpad] = useState();
    const [isFromAndroid, setisFromAndroid] = useState();

    const connection = useContext(ConnectionContext);
    const isUtenteInail = localStorage.getItem('isUtenteInail');

    const backButtonLogic = (oldstate) => { window.history.pushState(oldstate, ""); };

    useEffect(() => {
        const handleIframeNavigation = () => {
            window.scrollTo(0, 0);
        };

        window.addEventListener('message', handleIframeNavigation);

        return () => {
            window.removeEventListener('message', handleIframeNavigation);
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [navigate])

    useEffect(() => {
        let oldstate = window.history.state;
        window.history.pushState(oldstate, "");
        window.onpopstate = (event) => {
            if (event) backButtonLogic(oldstate);
        }
    }, []);

    useEffect(() => {
        try {
            getDeviceType();
            connection.invoke(HubEndpoint.SendMessageToSala, OperazioneSceltaMobile.VisualizzaTeletabber).then((response) => {
                if (!response.ok) dispatch(notificationOpen({ message: response.message, status: NotificationStatus.Error }));
            });
        } catch (error) {
            console.log('Errore durante l\'invocazione SignalR:', error);
            dispatch(notificationOpen({ message: Messaggi.ComunicazioneServerAssente, status: NotificationStatus.Error }));
            dispatch(hideLoader());
        }
        dispatch(hideLoader());
    }, []);

    const tornaIndietro = async () => {
        dispatch(showLoader());
        try {
            await connection.invoke(HubEndpoint.SendMessageToSala, OperazioneSceltaMobile.VisualizzaMenu).then((response) => {
                if (response.ok) {
                    navigate('/?isUtenteInail=' + isUtenteInail);
                } else {
                    dispatch(notificationOpen({ message: response.message, status: NotificationStatus.Error }));
                }
            });
        } catch (error) {
            console.log('Errore durante l\'invocazione SignalR:', error);
            dispatch(notificationOpen({ message: Messaggi.ComunicazioneServerAssente, status: NotificationStatus.Error }));
            dispatch(hideLoader());
        }
    }

    function getDeviceType() {
        const userAgent = navigator.userAgent;
        if (/iPhone/i.test(userAgent)) {
            setisFromIphone(true);
            setisFromIpad(false)
            setisFromAndroid(false)
            return IPHONE;
        } else if (/iPad/i.test(userAgent)) {
            setisFromIpad(true)
            setisFromIphone(true);
            setisFromAndroid(false)
            return IPAD;
        } else if (/Android/i.test(userAgent)) {
            setisFromAndroid(true)
            setisFromIpad(false)
            setisFromIphone(false);
            return ANDROID;
        } else {
            return 'Altro';
        }

    }

    return (
        <>
            <Grid container style={{ height: isFromIphone && isFromIpad ? '100dvh' : '100vh', minHeight: '100vh' }}>
                <div className="" style={{ height: '100dvh', width: '100%' }}>
                    <iframe
                        style={{
                            border: 'none',
                            // overflow: 'scroll',
                            scrollbarWidth: 'thin',
                            // height: '100vh',
                            height: isFromIphone ? 'calc(100dvh - 7vh)' : (isFromIpad ? '93vh' : '93vh'),
                            // marginTop: isFromIos ? '3vh' : '',

                            // 
                        }}
                        id="idTeletabber" title="teletabberFrame" src={teletabberUrl} allow='camera' className='iFrameTeletabber'></iframe>
                    <Grid container item xs={12} style={{ margin: '0px!important', padding: '0px!important', textAlign: 'center' , width: isNavigatingFromNotebook() ? '100%' : '' }} spacing={2} className={
                        !isNavigatingFromNotebook() 
                        ? "spaceAroundVisualization blueBackground bottomFixed" 
                        : "spaceAroundVisualization blueBackground bottomFixed_notebook"}>
                        <Grid item xs={12} sm={12} md={12} lg={12} style={{ margin: '0px!important', padding: '0px' }}>
                            <Box style={{ padding: '0px!important' }} className="goBackBtn btnHover" onClick={() => tornaIndietro()}>Torna al menu</Box>
                        </Grid>
                    </Grid>
                </div>

            </Grid >


            <Notification></Notification>
        </>
    )
}

export default Teletabber;