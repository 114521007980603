import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const salaSlice = createSlice({
    name:'salaInfo',
    initialState,
    reducers: {
        saveNomeSala: (state, payload) => {
            return payload.payload
         }
    }
});

export const { saveNomeSala } = salaSlice.actions;

const { reducer } = salaSlice;

export default reducer;