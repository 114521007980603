import { useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { Grid, Typography } from '@mui/material';
import { hideLoader } from "../../reducers/appLoader-reducer";
import "../../index.css";
import AppSettingContext from "../../context/AppSettingsContext";
import { isRunningInElectron } from "../../shared/functions/isRanningElectron";
import isNavigatingFromNotebook from "../../shared/functions/isNavigatingFromNotebook";
import { Row, Col } from "react-bootstrap";
import CustomButton from "../../shared/components/CustomButton";
import { RiavviaService } from "../../shared/services/riavvia.service";
const MessagePage = ({message}) => {    
    const appSettingContext = useContext(AppSettingContext);
    const service = new RiavviaService(appSettingContext);

    const dispatch = useDispatch();
    
    const backButtonLogic = (oldstate) => { window.history.pushState(oldstate, "");};

    useEffect(() => {
        let oldstate = window.history.state;
        window.history.pushState(oldstate, "");
        window.onpopstate = (event) => {
            if (event) backButtonLogic(oldstate);
        }
    }, []);

    useEffect(() => {
        dispatch(hideLoader());
    }, []);

    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={12} mt={10} className="spaceAroundVisualization">
                    <Typography variant="h5" className="boldBlueText" sx={{fontSize: '1.8rem'}}>{message}</Typography>
                </Grid>
            </Grid >
            {(isRunningInElectron() || isNavigatingFromNotebook()) && (
                        <Row className="d-flex justify-content-center mt-5 w-100">
                            <Col className="d-flex justify-content-center" xs={12} md={12} lg={12}>
                                <CustomButton classText="btn-text-size " size="" text="Riavvia" className={isNavigatingFromNotebook() ? 'w-50' : 'w-40'} onClickFn={async () => service.handleRiavvia()}></CustomButton>
                            </Col>

                        </Row>
                    )}
        </>
    )
}

export default MessagePage;