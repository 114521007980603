import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Grid, Typography } from '@mui/material';
import { hideLoader } from "../../reducers/appLoader-reducer";
import "../../index.css";

const HardResetAgent = ({message}) => {

    const dispatch = useDispatch();
    
    const backButtonLogic = (oldstate) => { window.history.pushState(oldstate, "");};

    useEffect(() => {
        let oldstate = window.history.state;
        window.history.pushState(oldstate, "");
        window.onpopstate = (event) => {
            if (event) backButtonLogic(oldstate);
        }
    }, []);

    useEffect(() => {
        dispatch(hideLoader());
    }, []);

    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={12} mt={10} className="centerVisualization">
                    <Typography variant="h5" className="boldBlueText" sx={{fontSize: '1.8rem', textAlign: 'center'}}>{message}</Typography>
                </Grid>
            </Grid >
        </>
    )
}

export default HardResetAgent;