import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect, Fragment, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Typography,
    DialogActions,
    Button,
} from "@mui/material";
import { closeDialogMobile , openDialogMobileForMutedAudio} from "../../../reducers/dialogMobile-reducer";
import { dialogButtonGeneral } from "../../../consts/dictionary";
import { isRunningInElectron } from "../../../shared/functions/isRanningElectron";
import '../../../index.css'



const electron = isRunningInElectron() ? window.require('electron') : null;

const breakpointY = 2736;
const breakpointX = 1824;


const MobileDialog = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    const dialogState = useSelector((state) => state.dialogMobile);
    const backButtonState = useSelector((state) => state.dialogBackButton);
    const dispatch = useDispatch();
    const [ok, setOk] = useState(false);
    const { rowInfo, dialogType, dialogPageType, message } = dialogState;

    //Gestisce il cambio di Datatable con la Dialog Aperta, la chiude e il dialogPageType impedisce di far partire la chiamata
    useEffect(() => {
        dispatch(closeDialogMobile());
    }, [window.onhashchange]);

    useEffect(() => {
        dispatch(closeDialogMobile());
    }, [backButtonState]);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
            setScreenHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const isScreenLargeEnough = () => {
        return screenWidth >= breakpointX && screenHeight >= breakpointY;
    }

    function openWindowsElectron() {
        var meeting = undefined;
        if (!dialogState.joinUrlMeeting) meeting = localStorage.getItem('URLMEETING')
        else meeting = dialogState.joinUrlMeeting;
        electron.ipcRenderer.send('openTeamsMeeting', meeting);
    }

    function openWindowsAndCloseDialog() {
        openWindowsElectron();
        dispatch(closeDialogMobile());
    }

    function handleOk() {
        setOk(true);
        localStorage.setItem('URLMEETING', dialogState.joinUrlMeeting)
        dispatch(dispatch(openDialogMobileForMutedAudio({
            dialogType: "mutedAudio",
            isEdit: false,
            message: 'Ricordati di mutare il microfono.',
        })))
    }

    return (
        <>
            {dialogState.dialogType === "reconnectedCode" && (
                <>
                    <Dialog
                        open={dialogState.isOpen}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth
                        maxWidth={isScreenLargeEnough() ? "lg" : "sm"}
                    >
                        <DialogTitle mb={3} sx={{ color: 'white', backgroundColor: '#002E5D', border: '1px solid white', borderRadius: '4px' }} className="fontfamily title-header">PIN recupero connessione</DialogTitle>
                        <DialogContent className="border-Content-dialog">
                            <DialogContentText id="alert-dialog-description" sx={{ textAlign: 'center' }}>
                                <Typography sx={{ fontSize: '2rem' }} className="title"><b>{dialogState.reconnectedCode}</b></Typography>
                                <Typography mt={1} className="fontfamily colorMessageDialog">{dialogState.message}</Typography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ mb: 1, justifyContent: 'center' }}>
                            <Button type="submit" variant="contained" className="primaryButton" sx={{ backgroundColor: '#002E5D', width: '50%', borderRadius: '20px!important' }} onClick={() => dispatch(closeDialogMobile())}>
                            <span className="fontfamily">{dialogButtonGeneral.Ok}</span>
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}

            {dialogState.dialogType === "openMeeting" && (
                <>
                    <Dialog
                        open={dialogState.isOpen}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth
                        maxWidth={isScreenLargeEnough() ? "lg" : "sm"}
                    >
                        <DialogTitle mb={3} sx={{ color: 'white', backgroundColor: '#002E5D', border: '1px solid white', borderRadius: '4px' }} className="fontfamily title-header">Apertura meeting</DialogTitle>
                        <DialogContent className="border-Content-dialog">
                            <DialogContentText id="alert-dialog-description" sx={{ textAlign: 'center' }}>
                                {/* <Typography sx={{ fontSize: '2rem' }} className="title"><b>{dialogState.reconnectedCode}</b></Typography> */}
                                <Typography mt={1} className="fontfamily colorMessageDialog">{dialogState.message}</Typography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ mb: 1, justifyContent: 'center' }}>
                            <Button type="submit" variant="contained" className="primaryButton" sx={{ backgroundColor: '#002E5D', width: '35%', borderRadius: '20px!important' }} onClick={handleOk}>
                            <span className="fontfamily">{dialogButtonGeneral.Si}</span>
                            </Button>
                            <Button type="submit" variant="contained" className="primaryButton" sx={{ backgroundColor: '#002E5D', width: '35%', borderRadius: '20px!important' }} onClick={() => dispatch(closeDialogMobile())}>
                            <span className="fontfamily">{dialogButtonGeneral.No}</span>
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}
            {dialogState.dialogType === "mutedAudio" && (
                <>
                    <Dialog
                        open={dialogState.isOpen}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth
                        maxWidth={isScreenLargeEnough() ? "lg" : "sm"}
                    >
                        <DialogTitle mb={3} sx={{ color: 'white', backgroundColor: '#002E5D', border: '1px solid white', borderRadius: '4px' }} className="fontfamily title-header">Apertura meeting</DialogTitle>
                        <DialogContent className="border-Content-dialog">
                            <DialogContentText id="alert-dialog-description" sx={{ textAlign: 'center' }}>
                                {/* <Typography sx={{ fontSize: '2rem' }} className="title"><b>{dialogState.reconnectedCode}</b></Typography> */}
                                <Typography mt={1} className="fontfamily colorMessageDialog">{dialogState.message}</Typography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ mb: 1, justifyContent: 'center' }}>
                            <Button type="submit" variant="contained" className="primaryButton" sx={{ backgroundColor: '#002E5D', width: '35%', borderRadius: '20px!important' }} onClick={openWindowsAndCloseDialog}>
                            <span className="fontfamily">{dialogButtonGeneral.Ok}</span>
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </>
    );
};

export default MobileDialog;
