import { Box } from "@mui/material";
import { useLocation } from 'react-router-dom';
import Loader from "../components/Loader";
import useCustomOrientation from "../hooks/useCustomOrientation";
import "../index.css";
import RotateDevice from "../pages/rotate_device/RotateDevice";
import Navbar from "./navbar/Navbar";
import isNavigatingFromNotebook from "../shared/functions/isNavigatingFromNotebook"
import { isRunningInElectron } from "../shared/functions/isRanningElectron";
import { display } from "@mui/system";
const Layout = ({ children }) => {
  const orientation = useCustomOrientation();
  const location = useLocation();
  const actualPath = location.pathname;
  return (
    <>
      <div>
        {isNavigatingFromNotebook()  &&
          <Box style={{ marginTop: `${(actualPath !== '/teletabber') ? '64px' : ''}`, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            {(actualPath !== '/teletabber') && <Navbar />}
            <Box component="main" className="backgroundImage"
              style={{ minHeight: `calc(100dvh - ${(actualPath !== '/teletabber') ? '64px' : ''}` }}
              sx={{
                alignItems: 'stretch',
                flex: 1,
                overflow: 'hidden',
              }}>
              <div className="app-container" style={{height: isNavigatingFromNotebook() ? '' : '100dvh'}}>
                <Box style={{
                  height: `${(actualPath !== '/teletabber') ? ' calc(100dvh - 64px)' : ''} `,
                  marginTop: (orientation.type.includes("landscape") && actualPath !== '/teletabber' && !orientation.type.includes("landscape"))
                    ? '55px'
                    : '',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  flexDirection:'column'
                }} className="cst-align-vertically">
                  {children}
                </Box>
              </div>

            </Box>
            <Loader />
          </Box>
        }
        <div>
          <Box style={{
            marginTop: `${(!orientation.type.includes("landscape") && actualPath !== '/teletabber' && !isRunningInElectron()) ? '' : ''}`,
            display: 'flex', flexDirection: 'column',
            overflow: 'hidden',
          }}>
            {(!orientation.type.includes("landscape") && actualPath !== '/teletabber') && <Navbar />}
            <Box component="main" className="backgroundImage"
              // style={{ minHeight: `calc(100dvh - ${(!orientation.type.includes("landscape") && actualPath !== '/teletabber') ? '' : ''}`,
          style={{minHeight:'100dvh'}}
            // }}
              sx={{
                alignItems: !orientation.type.includes("landscape") ? 'stretch' : 'center',
                flex: 1,
                overflow: 'hidden',
              }}>
              <Box style={{
                height: '100dvh',
                marginTop: (orientation.type.includes("landscape")
                  && actualPath !== '/teletabber'
                  && !orientation.type.includes("landscape"))
                  ? '55px'
                  : '',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                display:'flex',
                flexDirection: "column"
              }} className="cst-align-vertically">
                {/* {!orientation.type.includes("landscape") ? children : <RotateDevice />} */}
                {children}
              </Box>
            </Box>
            <Loader />
          </Box>
        </div>

      </div>


    </>
  );
};

export default Layout;
