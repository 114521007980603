import { useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { Grid } from "@mui/material"
import { HubEndpoint, Messaggi, NotificationStatus, OperazioneSceltaMobile } from "../../consts/dictionary"
import { hideLoader } from "../../reducers/appLoader-reducer"
import ConnectionContext from "../../context/ConnectionContext"
import buttonTeams from "../../assets/Microsoft_Teams.png"
import buttonTT from "../../assets/Teletabber.png"
import buttonAnnulla from "../../assets/Button_Annulla.png"
import buttonGuida from "../../assets/Button_Guida.png"
import "../../index.css";
import { useDispatch } from "react-redux"
import { notificationOpen } from "../../reducers/notification-reducer"
import Notification from "../../components/Notification"
import isNavigatingFromNotebook from "../../shared/functions/isNavigatingFromNotebook"
import { isRunningInElectron } from "../../shared/functions/isRanningElectron";
import { HomepageService } from "./homepage.service"
import checkIfTrue from "../../shared/functions/checkIfTrue";

const HomepageMobile = ({ isSalaOccupata, isUtenteInail, salaHasSlide }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const connection = useContext(ConnectionContext);
    const service = new HomepageService(connection,navigate, dispatch);

    const backButtonLogic = (oldstate) => { window.history.pushState(oldstate, ""); };

    useEffect(() => {
        let oldstate = window.history.state;
        window.history.pushState(oldstate, "");
        window.onpopstate = (event) => {
            if (event) backButtonLogic(oldstate);
        }
    }, []);

    useEffect(() => {
        try {
            connection.invoke(HubEndpoint.SendMessageToSala, OperazioneSceltaMobile.VisualizzaMenu).then((response) => {
                if (!response.ok && (!isRunningInElectron() || !isNavigatingFromNotebook())) dispatch(notificationOpen({ message: response.message, status: NotificationStatus.Error }));
            });
        } catch (error) {
            console.log('Errore durante l\'invocazione SignalR:', error);
            if (isRunningInElectron() || isNavigatingFromNotebook()) { }
            else {
                dispatch(notificationOpen({ message: Messaggi.ComunicazioneServerAssente, status: NotificationStatus.Error }));
            }
            dispatch(hideLoader());
        }
        dispatch(hideLoader());
    }, []);

    return (
        <>
            {
                isSalaOccupata &&
                <div>
                    {checkIfTrue(isUtenteInail)  &&
                        <Grid item xs={12} sm={12} md={6} >
                            <img src={buttonTeams} download="" alt='' className="imageAutoSize imageAutoSizeAccessTeamsHome " onClick={ async () => await service.accessTeams()} />
                        </Grid>
                    }
                    <Grid item xs={12} sm={12} md={6}>
                        <img src={buttonTT} download="" onClick={ async () => await service.visualizzaTeletabber()} alt='' className="imageAutoSize" />
                    </Grid>
                    <Grid container item xs={12} spacing={2} className="spaceAroundVisualization centerBtn">
                        <Grid item xs={5} sm={5} md={5} lg={4}>
                            <img src={buttonAnnulla} download="" onClick={ async () => await service.abbandona()} alt="" className="imageAutoSize imageAutoSizeBtn" />
                        </Grid>
                        {salaHasSlide && <Grid item xs={5} sm={5} md={5} lg={4}>
                            <img src={buttonGuida} download="" onClick={async () => await service.visualizzaGuida()} alt="" className="imageAutoSize imageAutoSizeBtn" />
                        </Grid>}
                    </Grid>
                </div>
            }
            <Notification></Notification>
        </>
    )

}


export default HomepageMobile;


