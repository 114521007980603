export const ID_SALA_KEY = "idSala";
export const QRCODE_TOKEN_KEY = "qrCodeToken";
export const IS_UTENTE_INAIL_KEY = "isUtenteInail";

export class Store {
    constructor(idSala, qrCodeToken, isUtenteInail) {
        this._idSala = idSala;
        this._qrCodeToken = qrCodeToken;
        this._isUtenteInail = isUtenteInail;
        this.storeParams()
    }

    get idSala() { return this._idSala ?? "" };
    get isUtenteInail() { return this._isUtenteInail ?? false };
    get qrCodeToken() { return this._qrCodeToken ?? "" };

    set idSala(value) { this._idSala = value; };
    set isUtenteInail(value) { this._isUtenteInail = value };
    set qrCodeToken(value) { this._qrCodeToken = value };

    // existParams() { return !!localStorage.getItem(ID_SALA_KEY) && !!localStorage.getItem(QRCODE_TOKEN_KEY) && !!localStorage.getItem(IS_UTENTE_INAIL_KEY) }
    storeParams() {
        localStorage.setItem(ID_SALA_KEY, this._idSala)
        localStorage.setItem(QRCODE_TOKEN_KEY, this._qrCodeToken)
        localStorage.setItem(IS_UTENTE_INAIL_KEY, this._isUtenteInail)
    }

}