import { isRunningInElectron } from "../functions/isRanningElectron";
const electron = isRunningInElectron() ? window.require('electron') : null;

export class RiavviaService{
    constructor(appSettingContext){
        this.appSettingContext =appSettingContext;
    }


    handleRiavvia(){
        if (isRunningInElectron()) {
            electron.ipcRenderer.send('riavviaApplicazione');
        } else window.location.href = this.appSettingContext.URL_APP_MOBILE;

    }
}