import { useEffect, useContext, useState } from 'react'
import { useNavigate } from "react-router-dom"
import { HubEndpoint, Messaggi, NotificationStatus, OperazioneSceltaMobile } from '../../../consts/dictionary'
import { showLoader, hideLoader } from "../../../reducers/appLoader-reducer"
import ConnectionContext from "../../../context/ConnectionContext"
import logoTeams from '../../../assets/Microsoft_Teams_Accedi.png'
import { useDispatch, useSelector } from 'react-redux'
import { notificationOpen } from '../../../reducers/notification-reducer'
import Notification from '../../../components/Notification';
import AppSettingContext from '../../../context/AppSettingsContext'
import '../../../index.css'
import { TeamAccessService } from './teamaccess.service';
import { Row, Col } from "react-bootstrap"
import CustomButton from '../../../shared/components/CustomButton';
import isNavigatingFromNotebook from '../../../shared/functions/isNavigatingFromNotebook'
const TeamsAccess = ({ windowReference, popupBlocked }) => {
    //HOOK
    const navigate = useNavigate();
    const state = useSelector((state) => state.notification);
    const dispatch = useDispatch();
    const connection = useContext(ConnectionContext);
    const appSetting = useContext(AppSettingContext);
    //STATE
    const [doPolling, setDoPolling] = useState(true);
    const [loggedUser, setLoggedUser] = useState();
    const [route, setRoute] = useState();
    const [connectionId, setConnectionId] = useState();
    //FUNCTIONS
    const backButtonLogic = (oldstate) => { window.history.pushState(oldstate, ""); };
    const service = new TeamAccessService(undefined, undefined,
        connection, navigate, dispatch,
        windowReference, appSetting, connectionId, setRoute, setLoggedUser, doPolling, setDoPolling);

    const goBack = async () => {
        dispatch(showLoader());
        let isUtenteInail = localStorage.getItem('isUtenteInail');
        let idSala = localStorage.getItem('idSala');
        let qrCodeToken = localStorage.getItem('qrCodeToken');
        try {
            if (connection._connectionState !== 'Connected') { await connection.start() }
            await goBackToMenu()
        } catch (error) {
            if (!!windowReference && (windowReference.closed || connection._connectionState !== 'Connected')) {
                connection.start();
                dispatch(hideLoader());
                await goBackToMenu();
                return;
            }
            console.log('Errore durante l\'invocazione SignalR:', error);
            dispatch(notificationOpen({ message: Messaggi.ComunicazioneServerAssente, status: NotificationStatus.Error }));
            dispatch(hideLoader());
        }

        async function goBackToMenu() {
            await connection.invoke(HubEndpoint.SendMessageToSala, OperazioneSceltaMobile.VisualizzaMenu).then((response) => {
                if (response.ok) {
                    navigate('/?idSala=' + idSala + '&isUtenteInail=' + isUtenteInail + '&qrCodeToken=' + qrCodeToken)
                } else {
                    dispatch(notificationOpen({ message: response.message, status: NotificationStatus.Error }))
                }
            })
        }
    };

    //USEEFFECT
    useEffect(() => {
        let oldstate = window.history.state;
        window.history.pushState(oldstate, "");
        window.onpopstate = (event) => {
            if (event) backButtonLogic(oldstate);
        }
    }, []);

    useEffect(() => {
        if (windowReference) windowReference.close();
        setConnectionId(connection.connectionId);
        try {
            connection.invoke(HubEndpoint.SendMessageToSala, OperazioneSceltaMobile.VisualizzaTeams).then((response) => {
                if (!response.ok) dispatch(notificationOpen({ message: response.message, status: NotificationStatus.Error }));
            });
        } catch (error) {
            console.log('Errore durante l\'invocazione SignalR:', error);
            dispatch(notificationOpen({ message: Messaggi.ComunicazioneServerAssente, status: NotificationStatus.Error }));
            dispatch(hideLoader());
        }
        dispatch(hideLoader());
    }, []);

    useEffect(() => {
        if (!windowReference) return;
        if (windowReference.closed && connection._connectionState !== 'Connected') connection.start();
    }, [])






    return (
        <>
            <Row>
                <Col>
                    <Row className="row m-0">
                        <Col className="d-flex justify-content-center">
                            <img src={logoTeams} download="" alt='' className="imageAutoSize" onClick={async () => await service.accessTeams()} />
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-center mx-0 w-100 mt-5">
                        <Col className="d-flex justify-content-center" xs={12} md={12} lg={12}>
                            <CustomButton classText="btn-text-size" size="" text="Indietro" className={isNavigatingFromNotebook() ?'w-50' : 'w-40'} onClickFn={async () => goBack()}></CustomButton>
                        </Col>

                    </Row>
                </Col>
            </Row>

            <Notification></Notification>
        </>
    )
}

export default TeamsAccess;


