import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import './App.css';
import Notification from "./components/Notification";
import MobileDialog from "./components/dialogs/mobile_dialog/MobileDialog";
import { HubEndpoint, InternalAppRoutes, ListenerSala, Messaggi, NotificationStatus } from "./consts/dictionary";
import AppSettingContext from "./context/AppSettingsContext";
import ConnectionContext from "./context/ConnectionContext";
import { RouteHistoryProvider } from "./context/RouteHistoryContext";
import { useRouteHistory } from "./context/RouteHistoryContext";
import Layout from "./layout/Layout";
import ErrorPage from "./pages/error_page/ErrorPage";
import Exit from "./pages/exit/Exit";
import HardResetAgent from "./pages/hard_reset_agent/HardResetAgent";
import Homepage from "./pages/homepage/Homepage";
import MessagePage from "./pages/message_page/MessagePage";
import NotebookAccessPin from "./pages/notebook_access_pin/NotebookAccessPin";
import RecuperaSessioneMobile from "./pages/recupera_sessione/RecuperaSessioneMobile";
import TeamsAccess from "./pages/teams_pages/teams_access/TeamsAccess";
import TeamsSelectAccount from "./pages/teams_pages/teams_select_account/TeamsSelectAccount";
import TeamsSelectAccountMobile from "./pages/teams_pages/teams_select_account/TeamsSelectAccountMobile";
import TeamsSelectMeeting from "./pages/teams_pages/teams_select_meeting/TeamsSelectMeeting";
import TeamsTelecomandoMobile from "./pages/teams_pages/teams_telecomando/TeamsTelecomandoMobile";
import Teletabber from "./pages/teletabber/Teletabber";
import { hideLoader, showLoader } from "./reducers/appLoader-reducer";
import { openDialogMobile } from "./reducers/dialogMobile-reducer";
import { notificationOpen } from "./reducers/notification-reducer";
import { disableGuida, enableGuida, enableRenderPermission } from "./reducers/permission-reducer";
import { saveNomeSala } from "./reducers/salaData-reducer";
import { saveUser } from "./reducers/userData-reducer";
import isNavigatingFromNotebook from "./shared/functions/isNavigatingFromNotebook";
import checkIfExist from "./shared/functions/checkIfExist";
import { ID_SALA_KEY, IS_UTENTE_INAIL_KEY, QRCODE_TOKEN_KEY } from "./store/storage";
import { isRunningInElectron } from "./shared/functions/isRanningElectron";
import HomepageMobile from "./pages/homepage/HomepageMobile";
import TeamsSelectMeetingMobile from "./pages/teams_pages/teams_select_meeting/TeamsSelectMeetingMobile";
import TeamsAccessMobile from "./pages/teams_pages/teams_access/TeamsAccessMobile";
import TeamsTelecomando from "./pages/teams_pages/teams_telecomando/TeamsTelecomando";
import RecuperaSessione from "./pages/recupera_sessione/RecuperaSessione";
import Guida from "./pages/guida/Guida";
import GuidaMobile from "./pages/guida/GuidaMobile";
function App() {
  let queryString = window.location.search;
  let params = new URLSearchParams(queryString);
  const { previousPath } = useRouteHistory()
  //se params esiste posso visualizzare la home
  //STATE
  const [connection, setConnection] = useState();
  const [hubUrl, setHubUrl] = useState();
  const [teletabberUrl, setTeletabberUrl] = useState();
  const [logoutMicrosoftUrl, setLogoutMicrosoftUrl] = useState();
  const [logoutOnNavigationBack, setLogoutOnNavigationBack] = useState();
  const [enablelogout, setEnableLogout] = useState();
  const [serverTimout, setServerTimout] = useState();
  const [arrayReconnectionSize, setArrayReconnectionSize] = useState();
  const [arrayReconnectionValue, setArrayReconnectionValue] = useState();
  const [appSetting, setAppSetting] = useState();
  const [windowReference, setWindowReference] = useState();
  const [appmobileurl, setAppMobileUrl] = useState();

  const [message, setMessage] = useState();
  const [idSala, setIdSala] = useState();
  const [qrCodeToken, setQrCodeToken] = useState();
  const [isUtenteInail, setIsUtenteInail] = useState("false");
  const [hasRedirected, setHasRedirected] = useState(false);
  const [popupBlocked, setPopupBlocked] = useState(null);
  const [showHome, setShowHome] = useState(previousPath === undefined ? false : (
    previousPath != "/sessioneterminata"
      ? true
      : false)
  );
  const [timeoutRedirectToAccessPage, setTimeoutRedirectToAccessPage] = useState();

  //HOOKS
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const actualPath = location.pathname;
  const salaHasSlide = useSelector((state) => state.permissionReducer.salaHasSlide);
  const canRender = useSelector((state) => state.permissionReducer.renderPermission);
  const isSalaOccupata = useSelector((state) => state.permissionReducer.isSalaOccupata);



  //FUNCTIONS

  const fetchSettings = async () => {
    try {
      const response = await axios.get("/appSettings.json");
      const data = response.data;
      setAppSetting(data);
      setHubUrl(data.REACT_APP_HUB_ADDRESS);
      setAppMobileUrl(data.URL_APP_MOBILE);
      setTeletabberUrl(!isRunningInElectron() ? data.REACT_APP_LINK_TELETABBER : data.REACT_APP_LINK_TELETABBER_ELECTRON);
      setLogoutMicrosoftUrl(data.LOGOUT_MICROSOFT_URL);
      setLogoutOnNavigationBack(data.LOGOUT_ON_NAVIGATION_BACK);
      setServerTimout(data.SERVER_TIMEOUT_MILLISECONDS);
      setArrayReconnectionSize(data.ARRAY_DIMENSION_RECONNECTION);
      setEnableLogout(data.ENABLE_LOGOUT);
      setArrayReconnectionValue(data.ARRAY_VALUE_RECONNECTION_MILLISECONDS);

      if (!isRunningInElectron() && params && params.size > 0) setShowHome(true);
      if (params && params.size > 0) { setIsUtenteInail(params.get('isUtenteInail')) }
    } catch (error) {
      console.error("Errore durante la richiesta:", error);
    }
  };

  const setRouteMobile = () => {
    let routeKey;
    var c = Object.entries(InternalAppRoutes).map(([key, value]) => {
      if (value === location.pathname.substring(1, location.pathname.length + 1)) {
        routeKey = key;
      }
      return routeKey;
    })
    return routeKey;
  };

  const setQueryParams = (obj) => {
    if (!obj) {
      setIdSala(params.get("idSala"));
      setQrCodeToken(params.get("qrCodeToken"))
      setIsUtenteInail(params.get("isUtenteInail"))
    } else {
      localStorage.setItem(ID_SALA_KEY, obj.idSala)
      localStorage.setItem(QRCODE_TOKEN_KEY, obj.qrCodeToken)
      localStorage.setItem(IS_UTENTE_INAIL_KEY, obj.isUtenteInail)
      setIdSala(obj.idSala);
      setQrCodeToken(obj.qrCodeToken)
      setIsUtenteInail(obj.isUtenteInail.toString())
    }
  };

  const storeParams = () => {
    localStorage.setItem(ID_SALA_KEY, params.get("idSala") ?? idSala)
    localStorage.setItem(QRCODE_TOKEN_KEY, params.get("qrCodeToken") ?? qrCodeToken)
    localStorage.setItem(IS_UTENTE_INAIL_KEY, params.get("isUtenteInail") ?? isUtenteInail)
  };

  const startConnection = async () => {
    try {
      const dataArray = new Array(arrayReconnectionSize).fill(arrayReconnectionValue);
      var token = localStorage.getItem(QRCODE_TOKEN_KEY) ?? qrCodeToken;
      let uri = `${hubUrl}?reconnectedCode=${token}`
      const connection = new HubConnectionBuilder()
        .withUrl(uri)
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect(dataArray)
        .build();

      connection.serverTimeoutInMilliseconds = serverTimout;

      connection.on(HubEndpoint.Disconnetti, () => {
        console.log("Disconnettiti");
        connection.stop();
      });


      connection.on(ListenerSala.CambiaPagina, (route, payload) => {

        switch (route) {
          case 8:
          case 9:
            setMessage(payload);
            break;
          default: if (payload) {
            dispatch(saveUser(payload));
          }
        }
        navigate('/' + InternalAppRoutes[route]);

      });

      connection.on(ListenerSala.SendError, (message) => {
        setShowHome(false);
        if (isRunningInElectron() || isNavigatingFromNotebook()) { }
        else { dispatch(notificationOpen({ message: message, status: NotificationStatus.Warning })); }

      });

      connection.on(ListenerSala.ReceiveUrlMicrosoftLogin, async (url) => {
        let loginWindowRef = window.open(url, "_blank");
        if (isPopupBlocked(loginWindowRef)) setPopupBlocked(true)
        else setPopupBlocked(false)
        setWindowReference(loginWindowRef);
      });

      connection.on(ListenerSala.ReceiveMessageFromHub, (message) => {
        if (message) {
          setMessage(message);
          navigate('/' + InternalAppRoutes[10]);
        }
      });

      connection.on(ListenerSala.ReconnectedUrlRedirect, (response) => {
        localStorage.setItem('isUtenteInail', response.mobileReconnectedData.isUtenteInail);
        localStorage.setItem('isMeetingSala', response.mobileReconnectedData.isMeetingSala);
        localStorage.setItem('slideIndex', response.mobileReconnectedData.slideIndex);
        localStorage.setItem(ID_SALA_KEY, response.mobileReconnectedData.idSala);
        localStorage.setItem(QRCODE_TOKEN_KEY, response.mobileReconnectedData.autoReconnectedCode);

        if (response.mobileReconnectedData.loggedUser) {
          dispatch(saveUser(response.mobileReconnectedData.loggedUser));
        }

        if (response.mobileReconnectedData.nomeSala) {
          dispatch(saveNomeSala(response.mobileReconnectedData.nomeSala));
        }

        if (response.mobileReconnectedData.salaHasSlide) {
          dispatch(enableGuida());
        } else {
          dispatch(disableGuida());
        }

        navigate('/' + InternalAppRoutes[response.route]);

      });

      connection.onreconnecting(error => {
        console.log(`Connessione persa a causa dell'errore:"${error}". Tentativo di riconnessione.`)
      });
      connection.onreconnected(connectionId => {
        console.log(`Connessione ristabilita. Connesso con connectionId "${connectionId}".`)
      });

      await connection.start();
      setConnection(connection);
    } catch (e) {
      console.error(e);
    }
  };

  const isPopupBlocked = (loginWindowRef) => {
    if (!!loginWindowRef && (loginWindowRef.closed || typeof (loginWindowRef) == 'undefined')) {
      loginWindowRef.close()
      return true;
    } else return false;
  }

  const occupaSala = async () => {
    dispatch(showLoader());
    var id = idSala == 'undefined' ? localStorage.getItem(ID_SALA_KEY) : idSala;
    try {
      if (checkIfExist(id)) {
        dispatch(hideLoader());
        navigate("/");
        return;
      }
      await connection.invoke(
        HubEndpoint.OccupaSala,
        parseInt(id),
        (qrCodeToken == 'undefined' ? localStorage.getItem(QRCODE_TOKEN_KEY) : qrCodeToken),
        true, (qrCodeToken == 'undefined' ? localStorage.getItem(QRCODE_TOKEN_KEY) : qrCodeToken)).then((response) => {
          if (response.ok) {
            if (response.result.nomeSala) {
              dispatch(saveNomeSala(response.result.nomeSala));
            }
            if (response.result.salaHasSlide) {
              dispatch(enableGuida());
            } else {
              dispatch(disableGuida());
            }
            dispatch(openDialogMobile({
              dialogType: "reconnectedCode",
              isEdit: false,
              message: 'Si prega di prendere nota di questo codice, sarà necessario in caso si debba ripristinare la connessione.',
              reconnectedCode: response.result.reconnectedCode
            }));
            dispatch(enableRenderPermission());
            if (isRunningInElectron() || isNavigatingFromNotebook() && location.pathname !== '/recuperasessione') {
              let uri = `/?idSala=${id}&qrCodeToken=${qrCodeToken}&isUtenteInail=${isRunningInElectron()}`;

              setShowHome(true);
              setIsUtenteInail(isRunningInElectron().toString());
              // setIsUtenteInail("true");

              navigate(uri)
            }
          } else {
            dispatch(enableRenderPermission());
            if (response.message === Messaggi.SalaOccupata)
              navigate('/' + InternalAppRoutes[9]);


          }
        });
      dispatch(hideLoader());
    } catch (error) {
      console.log('Errore durante l\'invocazione SignalR:', error);
      dispatch(hideLoader());
      dispatch(notificationOpen({ message: Messaggi.ComunicazioneServerAssente, status: NotificationStatus.Error }));
    }
  };

  //USEEFFECT


  useEffect(() => {
    const handleIframeNavigation = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener('message', handleIframeNavigation);

    return () => {
      window.removeEventListener('message', handleIframeNavigation);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [navigate])

  useEffect(() => {
    const isPageRefreshed = performance.navigation.type === performance.navigation.TYPE_RELOAD;
    const isHomepage = actualPath === '/';
    if (isPageRefreshed && !hasRedirected && !isHomepage) {
      navigate('/recuperasessione');
      setHasRedirected(true);
    }
  }, []);

  useEffect(() => {
    fetchSettings();
    if (isRunningInElectron() || isNavigatingFromNotebook()) dispatch(enableRenderPermission());
    if (!existParams()) setQueryParams()
    storeParams();
  }, []);



  useEffect(() => { if (!connection && hubUrl && !isNavigatingFromNotebook()) startConnection() }, [hubUrl]);

  useEffect(() => {
    setIdSala(localStorage.getItem(ID_SALA_KEY));
    setQrCodeToken(localStorage.getItem(QRCODE_TOKEN_KEY));
    setIsUtenteInail(params.get('isUtenteInail'));
    if (qrCodeToken && idSala && isUtenteInail
      && location.pathname !== '/recuperasessione') occupaSala();
  }, [connection]);

  useEffect(() => {
    setIsUtenteInail(localStorage.getItem(IS_UTENTE_INAIL_KEY));

    let routeKey = setRouteMobile();

    let connectionState;
    if (connection) connectionState = connection._connectionState;

    if (connectionState === 'Connected' && routeKey) {
      try {
        connection.invoke(HubEndpoint.SetRouteMobile, parseInt(routeKey)).then((response) => { });
      } catch (error) {
        console.log('Errore durante l\'invocazione SignalR:', error);
        dispatch(notificationOpen({ message: Messaggi.ComunicazioneServerAssente, status: NotificationStatus.Error }));
        dispatch(hideLoader());
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    fetchSettings();
  }, []);

  const existParams = () => { return !!localStorage.getItem(ID_SALA_KEY) && !!localStorage.getItem(QRCODE_TOKEN_KEY) && !!localStorage.getItem(IS_UTENTE_INAIL_KEY) }

  const handleAccess = async (childData) => {
    setQueryParams(childData);
    await startConnection();

  };


  if (canRender) {

    return (
      <ConnectionContext.Provider value={connection}>
        <AppSettingContext.Provider value={appSetting}>

          <Layout>
            <RouteHistoryProvider>
              <Routes>
                <Route path="/" element={
                  (isRunningInElectron() || isNavigatingFromNotebook()) && !showHome
                    ? <NotebookAccessPin onAccess={handleAccess} />
                    : (isSalaOccupata && showHome
                      ? (isRunningInElectron() || isNavigatingFromNotebook() ? <Homepage isSalaOccupata={isSalaOccupata} isUtenteInail={isUtenteInail} salaHasSlide={salaHasSlide} />
                        : <HomepageMobile isSalaOccupata={isSalaOccupata} isUtenteInail={isUtenteInail} salaHasSlide={salaHasSlide} />)
                      : <ErrorPage errorMessage={"Pagina non trovata."} />
                    )

                }
                />

                <Route path="/teamsaccess" element={
                  isSalaOccupata
                    ? ((isRunningInElectron() || isNavigatingFromNotebook())
                      ? <TeamsAccess windowReference={windowReference} popupBlocked={popupBlocked} />
                      : <TeamsAccessMobile windowReference={windowReference} popupBlocked={popupBlocked} />
                    )
                    : <MessagePage message={Messaggi.AccessoNonConsentito} />} />


                <Route path="/teamsselectaccount" element={
                  isSalaOccupata
                    ? (isRunningInElectron() || isNavigatingFromNotebook())
                      ? <TeamsSelectAccount windowReference={windowReference} setWindowReference={setWindowReference} logoutMicrosoftUrl={logoutMicrosoftUrl} canLogoutOnNavigationBack={logoutOnNavigationBack} enablelogout={enablelogout} />
                      : <TeamsSelectAccountMobile windowReference={windowReference} setWindowReference={setWindowReference} logoutMicrosoftUrl={logoutMicrosoftUrl} canLogoutOnNavigationBack={logoutOnNavigationBack} enablelogout={enablelogout} />
                    : <MessagePage message={Messaggi.AccessoNonConsentito} />} />

                <Route path="/teamsselectmeeting" element={
                  isSalaOccupata
                    ? (isRunningInElectron() || isNavigatingFromNotebook())
                      ? <TeamsSelectMeeting />
                      : <TeamsSelectMeetingMobile />
                    : <MessagePage message={Messaggi.AccessoNonConsentito} />} />

                <Route path="/guida" element={
                  isSalaOccupata
                    ? (isRunningInElectron() || isNavigatingFromNotebook())
                      ? <Guida />
                      : <GuidaMobile />
                    : <MessagePage message={Messaggi.AccessoNonConsentito} />} />
                <Route path="/sessioneterminata" element={isSalaOccupata ? <Exit message={message} /> : <MessagePage message={Messaggi.AccessoNonConsentito} />} />
                <Route path="/teamstelecomando" element={
                  isSalaOccupata
                    ? (isRunningInElectron() || isNavigatingFromNotebook())
                      ? <TeamsTelecomando />
                      : <TeamsTelecomandoMobile />
                    : <MessagePage message={Messaggi.AccessoNonConsentito} />} />
                <Route path="/message" element={<MessagePage message={isSalaOccupata ? message : Messaggi.AccessoNonConsentito} />} />
                <Route path="/recuperasessione" element={
                  (isRunningInElectron() || isNavigatingFromNotebook())
                    ? <RecuperaSessione />
                    : <RecuperaSessioneMobile />
                  } />
                <Route path="/hardresetagent" element={<HardResetAgent message={Messaggi.HardResetAgent} />} />
                <Route path="/helpdeskresetsala" element={<HardResetAgent message={Messaggi.HelpDeskResetSala} />} />
                <Route path="/teletabber" element={isSalaOccupata ? <Teletabber teletabberUrl={teletabberUrl} /> : <MessagePage message={Messaggi.AccessoNonConsentito} />} />
                {/* <Route path="*" element={<ErrorPage />} /> */}
              </Routes>
            </RouteHistoryProvider>

            <Notification></Notification>
          </Layout>
          <MobileDialog></MobileDialog>
        </AppSettingContext.Provider>
      </ConnectionContext.Provider>
    );
  } else {
    return (
      <ConnectionContext.Provider value={connection}>
        <AppSettingContext.Provider value={appSetting}>
          <Layout>
            <Routes>
              <Route path="/" element={<MessagePage message={''} />} />
              <Route path="/sessioneterminata" element={<Exit message={message} />} />
              <Route path="/message" element={<MessagePage message={message} />} />
              <Route path="/recuperasessione" element={
                  (isRunningInElectron() || isNavigatingFromNotebook())
                    ? <RecuperaSessione />
                    : <RecuperaSessioneMobile />
                  } />
              <Route path="*" element={<ErrorPage />} />
            </Routes>
            <Notification></Notification>
          </Layout>
          <MobileDialog></MobileDialog>
        </AppSettingContext.Provider>
      </ConnectionContext.Provider>
    );
  }
}

export default App;
