import { Grid } from '@mui/material'
import ConnectionContext from "../../context/ConnectionContext"
import fillPreviousPage from '../../assets/fillLeftArrow.png'
import fillNextPage from '../../assets/fillRightArrow.png'
import disabledPreviousPage from '../../assets/disabledLeftArrow.png'
import disabledNextPage from '../../assets/disabledRightArrow.png'
import buttonClose from '../../assets/Chiudi.png'
import playButton from '../../assets/playButton.png'
import pauseButton from '../../assets/pauseButton.png'
import "../../index.css";
import { useEffect, useState, useContext } from 'react'
import { HubEndpoint, NotificationStatus, OperazioneSceltaMobile, ComandiMultimedia } from '../../consts/dictionary'
import { useNavigate } from 'react-router-dom'
import { showLoader, hideLoader } from "../../reducers/appLoader-reducer"
import { Messaggi } from '../../consts/dictionary'
import { useDispatch, useSelector } from 'react-redux'
import { notificationOpen } from '../../reducers/notification-reducer'
import Notification from '../../components/Notification'
import isNavigatingFromNotebook from "../../shared/functions/isNavigatingFromNotebook";
import { Row, Col } from 'react-bootstrap'
import CustomButton from '../../shared/components/CustomButton'
const Guida = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const connection = useContext(ConnectionContext);
    const state = useSelector((state) => state.notification);
    const isUtenteInail = localStorage.getItem('isUtenteInail');
    const storedSlideIndex = localStorage.getItem('slideIndex');
    const [slideIndex, setSlideIndex] = useState(storedSlideIndex ? parseInt(storedSlideIndex) : 0);
    const [activeSlide, setActiveSlide] = useState();
    const [slideExtension, setSlideExtension] = useState();
    const [play, setPlay] = useState(true);


    const backButtonLogic = (oldstate) => { window.history.pushState(oldstate, ""); };

    useEffect(() => {
        let oldstate = window.history.state;
        window.history.pushState(oldstate, "");
        window.onpopstate = (event) => {
            if (event) backButtonLogic(oldstate);
        }
    }, []);

    useEffect(() => {
        try {
            connection.invoke(HubEndpoint.SendMessageToSala, OperazioneSceltaMobile.VisualizzaGuida).then((response) => {
                if (response.ok) {

                } else {
                    dispatch(notificationOpen({ message: response.message, status: NotificationStatus.Error }));
                }
            });
        } catch (error) {
            console.log('Errore durante l\'invocazione SignalR:', error);
            dispatch(notificationOpen({ message: Messaggi.ComunicazioneServerAssente, status: NotificationStatus.Error }));
            dispatch(hideLoader());
        }
        dispatch(hideLoader());
    }, []);

    useEffect(() => {
        let connectionState;
        if (connection) connectionState = connection._connectionState;
        if (connectionState === 'Connected') {
            getSlideGuida();
            localStorage.removeItem('slideIndex');
        } else {
            dispatch(notificationOpen({ message: Messaggi.ComunicazioneServerAssente, status: NotificationStatus.Error }));
        }
    }, [connection, slideIndex]);

    useEffect(() => {
        if (activeSlide) setPlayPauseListeners(activeSlide);
    }, [activeSlide]);

    const getSlideGuida = async () => {
        dispatch(showLoader());
        pauseContent();
        setPlay(true);
        try {
            connection.invoke(HubEndpoint.GetSlideGuida, parseInt(slideIndex)).then((response) => {
                setActiveSlide(response);
                setSlideExtension(response.estensione.slice(1));
                dispatch(hideLoader());
            });
        } catch (error) {
            console.error('Errore durante l\'invocazione SignalR:', error);
            dispatch(notificationOpen({ message: Messaggi.ComunicazioneServerAssente, status: NotificationStatus.Error }));
            dispatch(hideLoader());
        }
    };

    const goBack = async () => {
        dispatch(showLoader());
        pauseContent();
        try {
            await connection.invoke(HubEndpoint.SendMessageToSala, OperazioneSceltaMobile.VisualizzaMenu).then((response) => {
                if (response.ok) {
                    navigate('/?isUtenteInail=' + isUtenteInail);
                } else {
                    dispatch(notificationOpen({ message: response.message, status: NotificationStatus.Error }));
                }
            });
        } catch (error) {
            console.error('Errore durante l\'invocazione SignalR:', error);
            dispatch(notificationOpen({ message: Messaggi.ComunicazioneServerAssente, status: NotificationStatus.Error }));
            dispatch(hideLoader());
        }
    }

    const pauseContent = () => {
        let audioSlide = document.getElementById("audioSlide");
        let videoSlide = document.getElementById("videoSlide");
        if (audioSlide) audioSlide.pause();
        if (videoSlide) videoSlide.pause();
    }

    const playContent = () => {
        let audioSlide = document.getElementById("audioSlide");
        let videoSlide = document.getElementById("videoSlide");
        if (audioSlide) audioSlide.play();
        if (videoSlide) videoSlide.play();
    }

    const setPlayPauseListeners = (activeSlide) => {
        if (activeSlide.tipoFile === 2 || activeSlide.tipoFile === 3) {
            let multimediaTagRef = (activeSlide.tipoFile === 2) ? document.getElementById("audioSlide") : document.getElementById("videoSlide");
            multimediaTagRef.onplay = async (event) => {
                try {
                    await connection.invoke(HubEndpoint.SendMultimediaCommand, ComandiMultimedia.Play);
                } catch (error) {
                    console.error('Errore durante l\'invocazione SignalR:', error);
                    console.error('Evento scatenante: ', event);
                    dispatch(notificationOpen({ message: Messaggi.ComunicazioneServerAssente, status: NotificationStatus.Error }));
                }
            }
            multimediaTagRef.onpause = async (event) => {
                try {
                    await connection.invoke(HubEndpoint.SendMultimediaCommand, ComandiMultimedia.Pause);
                } catch (error) {
                    console.error('Errore durante l\'invocazione SignalR:', error);
                    console.error('Evento scatenante: ', event);
                    dispatch(notificationOpen({ message: Messaggi.ComunicazioneServerAssente, status: NotificationStatus.Error }));
                }
            }
        }
    }

    return (
        <>
            <Grid container>
                <Grid container item xs={12} spacing={2} className="centerVisualization">
                    <Grid item xs={activeSlide?.tipoFile === 2 ? 10 : 12} sm={activeSlide?.tipoFile === 2 ? 10 : 12} md={6} lg={6} mt={2}>
                        {activeSlide?.tipoFile === 1 && <img src={'data:image/' + slideExtension + ';base64,' + activeSlide?.file} download="" alt="" className="previewSlide" />}
                        {activeSlide?.tipoFile === 2 && <audio id="audioSlide" muted src={'data:audio/' + slideExtension + ';base64,' + activeSlide?.file} alt="" className="previewSlide" />}
                        {activeSlide?.tipoFile === 3 && <video id="videoSlide" muted src={'data:video/' + slideExtension + ';base64,' + activeSlide?.file} alt="" className="previewSlide" />}
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={2} className="spaceEvenlyVisualization">
                    <Grid item xs={3} sm={3} md={1} lg={1} mt={6}>
                        <img src={activeSlide?.isFirstSlide ? disabledPreviousPage : fillPreviousPage} download="" onClick={activeSlide?.isFirstSlide ? null : () => setSlideIndex(slideIndex - 1)} alt="" className="imageAutoSize" />
                    </Grid>
                    {(activeSlide?.tipoFile === 2 || activeSlide?.tipoFile === 3) && play &&
                        <Grid item xs={3} sm={3} md={1} lg={1} mt={6}>
                            <img src={playButton} onClick={() => { setPlay(false); playContent(); }} download="" alt="" className="imageAutoSize" />
                        </Grid>
                    }
                    {(activeSlide?.tipoFile === 2 || activeSlide?.tipoFile === 3) && !play &&
                        <Grid item xs={3} sm={3} md={1} lg={1} mt={6}>
                            <img src={pauseButton} onClick={() => { setPlay(true); pauseContent(); }} download="" alt="" className="imageAutoSize" />
                        </Grid>
                    }
                    <Grid item xs={3} sm={3} md={1} lg={1} mt={6}>
                        <img src={activeSlide?.isLastSlide ? disabledNextPage : fillNextPage} download="" onClick={activeSlide?.isLastSlide ? null : () => setSlideIndex(slideIndex + 1)} alt="" className="imageAutoSize" />
                    </Grid>
                </Grid>
                <Row className="d-flex w-100 justify-content-center mx-0  mt-5">
                    <Col className="d-flex justify-content-center" xs={12} md={12} lg={12}>
                        <CustomButton classText="btn-text-size" size="" text="Annulla" className={isNavigatingFromNotebook() ? 'w-50' : 'w-40'} onClickFn={async () => goBack()}></CustomButton>
                    </Col>

                </Row>
            </Grid >
            <Notification></Notification>
        </>
    )
}

export default Guida;