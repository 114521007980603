import { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { Grid, Typography, FormControl, RadioGroup } from '@mui/material';
import { HubEndpoint, InternalAppRoutes, Messaggi, NAVIGATION_KEY, NotificationStatus } from '../../../consts/dictionary';
import { showLoader, hideLoader } from "../../../reducers/appLoader-reducer";
import ConnectionContext from "../../../context/ConnectionContext";
import logoTeams from '../../../assets/Microsoft_Office_Teams_noBorder.png';
import buttonStart from '../../../assets/Avvia.png';
import buttonBack from '../../../assets/Indietro.png';
import MeetingOption from "../../../components/MeetingOption";
import "../../../index.css";
import { useDispatch, useSelector } from 'react-redux';
import { notificationOpen } from '../../../reducers/notification-reducer';
import Notification from '../../../components/Notification';
import { openDialogMobileForOpenMeeting } from '../../../reducers/dialogMobile-reducer';
import { isRunningInElectron } from '../../../shared/functions/isRanningElectron';
import { Meeting } from './Meeting';

const TeamsSelectMeetingMobile = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [meetings, setMeetings] = useState();
    const [userName, setUserName] = useState();
    const [selectedMeeting, setSelectedMeeting] = useState('');
    const state = useSelector((state) => state.notification);
    const connection = useContext(ConnectionContext);
    const [disableNavigationBtn, setDisableNavigationBtn] = useState();
    const [disableStartMeetingBtn, setDisableStartMeetingBtn] = useState();

    const backButtonLogic = (oldstate) => { window.history.pushState(oldstate, ""); };

    useEffect(() => {
        let oldstate = window.history.state;
        window.history.pushState(oldstate, "");
        window.onpopstate = (event) => {
            if (event) {
                backButtonLogic(oldstate);
            }
        }
        disableNavigationBtnFn();
    }, [connection]);




    useEffect(() => {
        async function fetchData() {
            try {
                const queryString = window.location.search;
                const params = new URLSearchParams(queryString);
                let _storedSalaUser = params.get('isSalaUser') ?? localStorage.getItem(NAVIGATION_KEY).split('=')[1];
                let _storedIsMeetingSala = localStorage.getItem('isMeetingSala');
                const isSalaUser = _storedSalaUser ? _storedSalaUser === "true" : _storedIsMeetingSala === "true";
                const getMeetingsUrl = isSalaUser ? HubEndpoint.GetRiunioniSala : HubEndpoint.GetRiunioniPersonali;
                await connection.invoke(getMeetingsUrl).then((response) => {
                    const meetingList = response.eventi.map(x => {
                        return new Meeting(
                            x.onlineMeeting?.joinUrl,
                            x.id,
                            x.start.dateTime,
                            x.end.dateTime,
                            x.subject
                        )
                    }
                    )
                    setMeetings(meetingList);
                    setUserName(response.meetingSubject);
                    dispatch(hideLoader());
                });
            } catch (error) {
                console.log('Errore durante l\'invocazione SignalR:', error);
                dispatch(notificationOpen({ message: Messaggi.ComunicazioneServerAssente, status: NotificationStatus.Error }));
                dispatch(hideLoader());
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        setDisableStartMeetingBtn((!!selectedMeeting && selectedMeeting != '') ? false : true)
    }, [selectedMeeting]);

    const startMeeting = async () => {
        if (!selectedMeeting) return;
        if (isRunningInElectron()) {
            dispatch(openDialogMobileForOpenMeeting({
                dialogType: "openMeeting",
                isEdit: false,
                message: 'Desideri avviare il meeting anche sul tuo pc?',
                joinUrlMeeting: selectedMeeting
            }))
            dispatch(showLoader());
        }
        try {
            await connection.invoke(HubEndpoint.AvviaRiunione, selectedMeeting);
            navigate('/' + InternalAppRoutes[4]);
        } catch (error) {
            console.log('Errore durante l\'invocazione SignalR:', error);
            dispatch(notificationOpen({ message: Messaggi.ComunicazioneServerAssente, status: NotificationStatus.Error }));
            dispatch(hideLoader());
        }
    }

    const goBack = async () => {
        if (!disableNavigationBtn) {
            dispatch(showLoader());
            navigate('/' + InternalAppRoutes[2]);
        }
    }

    const disableNavigationBtnFn = (isDisconnected) => {
        setDisableNavigationBtn(isDisconnected);
        if (!isDisconnected) return
        dispatch(notificationOpen({ message: 'Connessione persa. Attendere il ripristino', status: NotificationStatus.Error }));

    }

    const handleOnlineStatusChange = (value) => {
        disableNavigationBtnFn(value);
    };

    connection.onreconnecting(connectionId => {
        handleOnlineStatusChange(true);
    });
    connection.onreconnected(connectionId => {
        handleOnlineStatusChange(false);
    });

    function getDataFormattata() {
        const oggi = new Date();
        const formattatore = new Intl.DateTimeFormat('it-IT', {
            day: '2-digit',
            month: '2-digit'
        });

        const dataFormattata = formattatore.format(oggi);
        return dataFormattata;
    }



    return (
        <>
            <Grid container className="meetingList">
                <Grid container item xs={12} spacing={0} className="centerVisualization" style={{ height: '120px' }}>
                    <Grid item xs={3} sm={3} md={3} lg={3}>
                        <img src={logoTeams} alt='' download="" className="imageThreeQuarterSize" />
                    </Grid>
                    <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography variant="h5" className="boldBlueText title" sx={{ fontSize: '1.4rem', textAlign: 'left' }}>Elenco dei meeting</Typography>
                        <Typography variant="h3" className="boldBlueText subTitle" sx={{ fontSize: '1.4rem', textAlign: 'left' }}>{userName}</Typography>
                    </Grid>

                </Grid>

                {meetings && meetings.length > 0 &&
                    <Grid container item xs={12} className="scrollable list" style={{
                        display: 'flex', justifyContent: 'space-around',
                        paddingLeft: '25px',
                        paddingRight: '0px',
                        width: '100%',
                        marginTop: '16px'
                    }} >
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormControl style={{ width: '100%' }} >
                                <RadioGroup style={{ maxHeight: '50dvh', minHeight: '50dvh', overflowY: 'scroll', display: 'flex', flexWrap: 'nowrap', flexDirection: 'column' }} defaultValue="" name="meetings-group" value={selectedMeeting} onChange={(event) => setSelectedMeeting(event.target.value)}>
                                    {meetings.map((meeting) =>
                                        <MeetingOption
                                            onSelect={(value) => setSelectedMeeting(value)}
                                            key={meeting.id}
                                            isSelected={selectedMeeting === meeting.url}
                                            isPastEvent={meeting.isEventoPassato}
                                            meeting={meeting}
                                        />
                                    )}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid container item xs={12} spacing={2} className={`spaceAroundVisualization  goBackStartbtn`} style={{
                            marginTop: meetings && meetings.length === 0 ? '5rem' : '1rem',
                            display: 'flex',
                            position: 'fixed',
                            bottom: '1.5rem',
                            justifyContent: 'space-evenly',
                        }}>
                            <Grid item xs={4} sm={3} md={2} >
                                <img src={buttonBack} alt='' download=""
                                    className={`imageFullSize  ${disableNavigationBtn ? 'disabled' : ''} `}
                                    disabled={disableNavigationBtn} onClick={() => disableNavigationBtn ? {} : goBack()} />
                            </Grid>
                            {meetings && meetings.length > 0 && <Grid item xs={4} sm={3} md={2}>
                                <img src={buttonStart} alt='' download="" className={`imageFullSize  ${disableStartMeetingBtn ? 'disabled' : ''}`} onClick={() => startMeeting()} /></Grid>
                            }
                        </Grid>

                    </Grid>
                }
                {meetings && meetings.length === 0 &&
                    <Grid container item xs={12} mt={2} ml={2} mb={2} className="centerVisualization" style={{ height: '100vh' }}>
                        <Typography variant="h5" className="boldBlueText" sx={{ fontSize: '1.4rem', textAlign: 'center' }}>Non ci sono Meeting in programma per oggi.</Typography>
                        <Grid container item xs={12} spacing={2} className="spaceAroundVisualization goBackStartbtn" style={{
                            marginTop: meetings && meetings.length === 0 ? '5rem' : '1rem',
                            display: 'flex',
                            position: 'fixed',
                            bottom: '1.5rem',
                            justifyContent: 'space-evenly'
                        }}>
                            <Grid item xs={4} sm={3} md={2} className="">
                                <img src={buttonBack} alt='' download=""
                                    className={`imageFullSize  ${disableNavigationBtn ? 'disabled' : ''}`}
                                    disabled={disableNavigationBtn} onClick={() => disableNavigationBtn ? {} : goBack()} />
                            </Grid>
                            {meetings && meetings.length > 0 && <Grid item xs={4} sm={3} md={2} className="">
                                <img src={buttonStart} alt='' download="" className={`imageFullSize  ${disableStartMeetingBtn ? 'disabled' : ''}`} onClick={() => disableStartMeetingBtn ? {} : startMeeting()} /></Grid>
                            }
                        </Grid>
                    </Grid>
                }


            </Grid >
            <Notification></Notification>
        </>
    )
}

export default TeamsSelectMeetingMobile;