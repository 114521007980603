import { Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import "../../index.css";
import { hideLoader } from "../../reducers/appLoader-reducer";

const ErrorPage = ({ errorMessage }) => {
  const dispatch = useDispatch();

  const backButtonLogic = (oldstate) => { window.history.pushState(oldstate, ""); };

  useEffect(() => {
    let oldstate = window.history.state;
    window.history.pushState(oldstate, "");
    window.onpopstate = (event) => {
      if (event) backButtonLogic(oldstate);
    }
  }, []);

  useEffect(() => {
    dispatch(hideLoader());
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} mt={10} className="centerVisualization">
          <Typography variant="h6" className="boldBlueText" sx={{ fontSize: '1.8rem' }}>{errorMessage ? errorMessage : 'Pagina non trovata.'}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default ErrorPage;
