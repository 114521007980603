export class Meeting {
    constructor(url, id, start, end, subject) {
        this.url = url;
        this.id = id;
        this.start = start;
        this.end = end;
        this.subject = subject;
        this.isEventoPassato = this.getIfEventoPassato();
    }

    getIfEventoPassato() {
        const now = new Date();
        const endDateTime = new Date(this.end);
        if (endDateTime > now) {
            return true;
        }else{
            return false;
        }
    }
}

