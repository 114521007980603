import { useContext, useEffect } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import buttonTeams from "../../assets/Microsoft_Teams.png"
import buttonTT from "../../assets/Teletabber.png"
import Notification from "../../components/Notification"
import { HubEndpoint, Messaggi, NotificationStatus, OperazioneSceltaMobile } from "../../consts/dictionary"
import ConnectionContext from "../../context/ConnectionContext"
import "../../index.css"
import { hideLoader } from "../../reducers/appLoader-reducer"
import { notificationOpen } from "../../reducers/notification-reducer"
import CustomButton from "../../shared/components/CustomButton"
import checkIfTrue from "../../shared/functions/checkIfTrue"
import isNavigatingFromNotebook from "../../shared/functions/isNavigatingFromNotebook"
import { isRunningInElectron } from "../../shared/functions/isRanningElectron"
import { HomepageService } from "./homepage.service"
import { Row , Col } from "react-bootstrap";

const Homepage = ({ isSalaOccupata, isUtenteInail, salaHasSlide }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const connection = useContext(ConnectionContext);
    const service = new HomepageService(connection,navigate, dispatch);
    const backButtonLogic = (oldstate) => { window.history.pushState(oldstate, ""); };
    
    useEffect(() => {
        let oldstate = window.history.state;
        window.history.pushState(oldstate, "");
        window.onpopstate = (event) => {
            if (event) backButtonLogic(oldstate);
        }
    }, []);

    useEffect(() => {
        try {
            connection.invoke(HubEndpoint.SendMessageToSala, OperazioneSceltaMobile.VisualizzaMenu).then((response) => {
                if (!response.ok && (!isRunningInElectron() || !isNavigatingFromNotebook())) dispatch(notificationOpen({ message: response.message, status: NotificationStatus.Error }));
            });
        } catch (error) {
            console.log('Errore durante l\'invocazione SignalR:', error);
            if (isRunningInElectron() || isNavigatingFromNotebook()) { }
            else {
                dispatch(notificationOpen({ message: Messaggi.ComunicazioneServerAssente, status: NotificationStatus.Error }));
            }
            dispatch(hideLoader());
        }
        dispatch(hideLoader());
    }, []);

    

    return (
        <>
            {isSalaOccupata && <div>
                {checkIfTrue(isUtenteInail) &&
                    <Row className="m-0">
                        <Col className="d-flex justify-content-center">
                            <img src={buttonTeams} download="" alt='' className="imageAutoSize" onClick={ async () => await service.accessTeams()} />
                        </Col>
                    </Row>
                }
                <Row className={`${isRunningInElectron() ? '' : 'pt-3'}  ${!checkIfTrue(isUtenteInail) ? 'mb-5 pb-5' : 'm-0'}`}>
                    <Col  className="d-flex justify-content-center">
                        <img src={buttonTT} download="" onClick={ async () => await service.visualizzaTeletabber()} alt='' className="imageAutoSize" />
                    </Col>
                </Row>
                <div>
                    <Row className={`${!salaHasSlide ? 'd-flex justify-content-center' : ''}  mt-5`}>
                        <Col className="d-flex justify-content-center" xs={salaHasSlide ? 6 : 12} md={salaHasSlide ? 6 : 12} lg={salaHasSlide ? 6 : 12}>
                            <CustomButton classText="btn-text-size" size=""  text="Esci" className={isNavigatingFromNotebook() ?'w-70' : 'w-70'} onClickFn={ async () => await service.abbandona()}></CustomButton>
                        </Col>
                        {salaHasSlide &&
                            <Col className="d-flex justify-content-center"  xs={6} md={6} lg={6}>
                                <CustomButton classText="btn-text-size" size="" text="Guida" className={isNavigatingFromNotebook() ?'w-70' : 'w-70'} onClickFn={ async () => await service.visualizzaGuida()}></CustomButton>
                            </Col>
                        }
                    </Row>
                </div>
            </div>
            }
            <Notification></Notification>
        </>
    )

}
export default Homepage;


