import { Box, Button, Grid, Typography } from '@mui/material';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logoTeams from '../../../assets/Microsoft_Office_Teams_noBorder.png';
import { apiMIP, InternalAppRoutes, NotificationStatus, NAVIGATION_KEY} from '../../../consts/dictionary';
import ConnectionContext from "../../../context/ConnectionContext";
import AppSettingContext from "../../../context/AppSettingsContext";
import "../../../index.css"
import { hideLoader, showLoader } from "../../../reducers/appLoader-reducer";
import { notificationOpen } from '../../../reducers/notification-reducer';
import { isRunningInElectron } from '../../../shared/functions/isRanningElectron';
import isNavigatingFromNotebook from '../../../shared/functions/isNavigatingFromNotebook';

const TeamsSelectAccountMobile = ({ windowReference, setWindowReference, logoutMicrosoftUrl, canLogoutOnNavigationBack, enablelogout }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.userData);
    const salaData = useSelector((state) => state.salaData);
    const connection = useContext(ConnectionContext);
    const appSetting = useContext(AppSettingContext);
    const [disableNavigationBtn, setDisableNavigationBtn] = useState();

    const { givenName, surname, mail } = userData;
    let connectionId = connection.connection.connectionId;

    //USEEFFECT
    useEffect(() => {
        let oldstate = window.history.state;
        window.history.pushState(oldstate, "");
        window.onpopstate = (event) => {
            if (event) backButtonLogic(oldstate);
        }
    }, []);

    useEffect(() => {
        if (windowReference) windowReference.close();
        dispatch(hideLoader());
    }, []);


    //FUNCTIONS
    const backButtonLogic = (oldstate) => { window.history.pushState(oldstate, ""); };

    const getUserMeetings = () => {
        localStorage.removeItem(NAVIGATION_KEY);
        dispatch(showLoader());
        if (!disableNavigationBtn) {
            let uri = `/${InternalAppRoutes[3]}?isSalaUser=false`;
            localStorage.setItem(NAVIGATION_KEY, uri);
            navigate(uri, { state: { from: window.location.pathname } });
        }
    }

    const getSalaMeetings = () => {
        localStorage.removeItem(NAVIGATION_KEY);
        dispatch(showLoader());
        if (!disableNavigationBtn) {
            let uri = `/${InternalAppRoutes[3]}?isSalaUser=true`;
            localStorage.setItem(NAVIGATION_KEY, uri);
            navigate(uri, { state: { from: window.location.pathname } });
        }
    }

    const goBack = async () => {
        if (canLogoutOnNavigationBack) {
            navigate('/' + InternalAppRoutes[1]);
            axios.get(`${appSetting.URL_API}/${apiMIP.logout}?connectionId=${connectionId}`).then(response => {
                
            }).catch(error => { console.error('Error:', error); });
            return;
        }
        let logoutUrl = logoutMicrosoftUrl + connectionId;
        let logoutWindowReference = window.open(logoutUrl);
        setWindowReference(logoutWindowReference);
    }

    const logout = () => {
        let logoutUrl = logoutMicrosoftUrl + connectionId;
        let logoutWindowReference = window.open(logoutUrl);
        setWindowReference(logoutWindowReference);
    }

    const disableNavigationBtnFn = (isDisconnected) => {
        setDisableNavigationBtn(isDisconnected);
        if (!isDisconnected) return
        dispatch(notificationOpen({ message: 'Connessione persa. Attendere il ripristino', status: NotificationStatus.Error }));

    }

    const handleOnlineStatusChange = (value) => { disableNavigationBtnFn(value);};

    connection.onreconnecting(connectionId => { handleOnlineStatusChange(true); });

    connection.onreconnected(connectionId => { handleOnlineStatusChange(false); });


    return (
        <>
            <Grid container  className='container-home'>
                <Grid container item xs={12} spacing={2} mt={2} className="centerVisualization">
                    <img src={logoTeams} alt='' download="" className="image30Width" />
                </Grid>
                <Grid item xs={12} mt={4} className="centerVisualization">
                    <Typography variant="h5" className="boldBlueText" sx={{ fontSize: '1.8rem' }}>Scegli l'account</Typography>
                </Grid>
                <Grid item xs={12} mt={4} className="centerVisualization" onClick={() => disableNavigationBtn ? {} : getUserMeetings()}>
                    <Box className={`buttonSelectAccount blueBackground ${disableNavigationBtn ? 'disabled' : ''}`}>
                        <Typography className="boldWhiteText accountName hovered" >{givenName} {surname}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} mt={4} className="centerVisualization" onClick={() => disableNavigationBtn ? {} : getSalaMeetings()}>
                    <Box className={`buttonSelectAccount blueBackground ${disableNavigationBtn ? 'disabled' : ''}`}>
                        <Typography className="boldWhiteText accountName hovered">{salaData}</Typography>
                    </Box>
                </Grid>
                <Grid container item xs={12} mt={2} spacing={2} className="centerVisualization">
                    <Grid item xs={6} sm={6} md={4} lg={6} mt={4} style={{ paddingTop: '19px' }}>
                        <Button className="btn-cst btnHover" sx={{
                            backgroundColor: '#002E5D',
                            color: 'white',
                            maxWidth: '10rem',
                            fontWeight:'400',
                            fontSize:'1rem',
                            width: '9rem',
                            height: isRunningInElectron() || isNavigatingFromNotebook() ? '32.7px;': '1.7rem',
                            borderRadius: '20px', textTransform: 'unset',
                            padding: '0.4rem 2rem',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontFamily: "Segoe UI,sans-serif"
                        }} type="button" onClick={() => goBack()}>
                            Torna al menu
                        </Button>
                    </Grid>

                    {/* <Grid item xs={6} sm={6} md={4} lg={3} mt={4}>
                        <img src={buttonBackToMenu} alt='' download="" className="imageAutoSize" onClick={() => goBack()} />
                    </Grid> */}
                    {enablelogout && (
                        <>
                            <Grid item xs={6} sm={6} md={4} lg={6} mt={4} style={{ paddingTop: '19px' }}>
                                <Button className="btn-cst btnHover" sx={{
                                    backgroundColor: '#002E5D',
                                    color: 'white',
                                    fontWeight:'400',
                                    fontSize:'1rem',
                                    maxWidth: '10rem',
                                    width: '9rem',
                                    height: isRunningInElectron() || isNavigatingFromNotebook() ? '32.7px;': '1.7rem',
                                    borderRadius: '20px', textTransform: 'unset',
                                    padding: '0.4rem 2rem',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    fontFamily: "Segoe , sans-serif"
                                }} type="button" onClick={() => logout()}>
                                    Logout
                                </Button>
                            </Grid>
                        </>
                    )}

                </Grid>
            </Grid >
        </>
    )
}

export default TeamsSelectAccountMobile;