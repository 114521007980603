import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ID_SALA_KEY } from "../../store/storage";
import { Grid, FormControl, TextField, DialogActions, Button, Card, CardContent, Typography, CardActions } from "@mui/material";
import { hideLoader, showLoader } from "../../reducers/appLoader-reducer";
import "../../index.css";
import { useState } from "react";
import { initialValuesRecuperoSessioneAdd } from "../../consts/formik/RecuperoSessione/initialValuesRecuperoSessione";
import { validationSchemaRecuperoSessioneAdd } from "../../consts/formik/RecuperoSessione/validationSchemaRecuperoSessione";
import { useFormik } from "formik";
import { notificationOpen } from "../../reducers/notification-reducer";
import { HubEndpoint, Messaggi, NotificationStatus, dialogButtonGeneral } from "../../consts/dictionary";
import ConnectionContext from "../../context/ConnectionContext";
import OtpInput from "react18-input-otp";
import { enableRenderPermission } from "../../reducers/permission-reducer";
import { width } from "@mui/system";

const RecuperaSessione = () => {

  const connection = useContext(ConnectionContext);
  const dispatch = useDispatch();
  const backButtonLogic = (oldstate) => { window.history.pushState(oldstate, ""); };
  const [changedRow, setchangedRow] = useState();
  let initialValues = initialValuesRecuperoSessioneAdd();
  let validationSchema = validationSchemaRecuperoSessioneAdd;

  // useEffect(() => {
  //   let oldstate = window.history.state;
  //   window.history.pushState(oldstate, "");
  //   window.onpopstate = (event) => {
  //     if (event) backButtonLogic(oldstate);
  //   }
  // }, []);

  useEffect(() => {
    dispatch(hideLoader());
  }, []);


  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setchangedRow({
        ReconnectedCode: values.reconnectedCode,
      });
      formik.resetForm(initialValues);
    }
  });

  useEffect(() => {
    if (changedRow != null) {
      (async () => {
        dispatch(showLoader())
        let reconnectedCode;
        reconnectedCode = changedRow.ReconnectedCode;
        try {
          await connection.invoke(HubEndpoint.RecuperoSessione, parseInt(+reconnectedCode)).then((response) => {
            if (response.ok) {
              formik.resetForm(initialValues);
/*                dispatch(
                notificationOpen({
                  message: response.data.message,
                  status: response.data.ok ? "success" : "error",
                })
              );  */
            }  else {
              dispatch(notificationOpen({ message: response.message, status: NotificationStatus.Error }));
            } 
          });
        } catch (error) {
          console.log('Errore durante l\'invocazione SignalR:', error);
          dispatch(notificationOpen({ message: Messaggi.ComunicazioneServerAssente, status: NotificationStatus.Error }));
          dispatch(hideLoader());
        }
        dispatch(hideLoader())
      })();
    }
    dispatch(enableRenderPermission());

  }, [changedRow]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <FormControl fullWidth >
          <Grid container rowSpacing={1} columnSpacing={2} sx={{ paddingX: '3rem!important' }}>
            <Grid sx={{ width: '100%'}} item>
              <Card className="PINCard">
                <CardContent>
                  <Typography mb={3} sx={{ fontSize: '1rem' }} color="text.secondary" gutterBottom>
                    <b className="fontfamily text-center cl-black">Inserisci il PIN di riconnessione</b>
                  </Typography>
                  <OtpInput
                    isInputNum
                    containerStyle="PINInput"
                    inputStyle={{ padding: '0.5rem', width:'2em!important' }}
                    value={formik.values.reconnectedCode}
                    onChange={formik.handleChange("reconnectedCode")}
                    numInputs={4}
                    separator={<span style={{ fontFamily: 'Roboto', fontSize: '1.5rem', paddingInline: '0.5rem' }}>-</span>}
                    autoComplete="off"
                    hasErrored={formik.touched.reconnectedCode && Boolean(formik.errors.reconnectedCode)}
                  />
                  {formik.touched.reconnectedCode && Boolean(formik.errors.reconnectedCode) &&
                    <Typography mt={1} sx={{ fontSize: '0.9rem', color: 'red', textAlign: 'center' }} color="text.secondary" gutterBottom>
                      {formik.touched.reconnectedCode && formik.errors.reconnectedCode}
                    </Typography>}
                </CardContent>
                <CardActions>
                  <DialogActions sx={{ mt: 3 }}>
                    <Button variant="text" sx={{ color: '#002E5D' }} onClick={() => { formik.resetForm(initialValues) }}>
                     <span className="fontfamily">{dialogButtonGeneral.Reset}</span> 
                    </Button>
                    <Button type="submit" variant="contained" className="primaryButton">
                    <span className="fontfamily">{dialogButtonGeneral.Invia}</span> 
                    </Button>
                  </DialogActions>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </FormControl>
      </form>
    </>
  );
};

export default RecuperaSessione;
