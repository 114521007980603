import { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { Grid, Typography, FormControl, RadioGroup } from '@mui/material';
import { HubEndpoint, InternalAppRoutes, Messaggi, NAVIGATION_KEY, NotificationStatus } from '../../../consts/dictionary';
import { showLoader, hideLoader } from "../../../reducers/appLoader-reducer";
import ConnectionContext from "../../../context/ConnectionContext";
import logoTeams from '../../../assets/Microsoft_Office_Teams_noBorder.png';
import MeetingOption from "../../../components/MeetingOption";
import "../../../index.css";
import { useDispatch, useSelector } from 'react-redux';
import { notificationOpen } from '../../../reducers/notification-reducer';
import Notification from '../../../components/Notification';
import { openDialogMobileForOpenMeeting } from '../../../reducers/dialogMobile-reducer';
import isNavigatingFromNotebook from '../../../shared/functions/isNavigatingFromNotebook';
import { isRunningInElectron } from '../../../shared/functions/isRanningElectron';
import { Row, Col } from "react-bootstrap";
import CustomButton from '../../../shared/components/CustomButton';
import { Meeting } from './Meeting';
const TeamsSelectMeeting = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [meetings, setMeetings] = useState();
    const [userName, setUserName] = useState();
    const [selectedMeeting, setSelectedMeeting] = useState('');
    const state = useSelector((state) => state.notification);
    const connection = useContext(ConnectionContext);
    const [disableNavigationBtn, setDisableNavigationBtn] = useState();
    const [disableStartMeetingBtn, setDisableStartMeetingBtn] = useState();

    const backButtonLogic = (oldstate) => { window.history.pushState(oldstate, ""); };



    useEffect(() => {
        let oldstate = window.history.state;
        window.history.pushState(oldstate, "");
        window.onpopstate = (event) => {
            if (event) {
                backButtonLogic(oldstate);
            }
        }
        disableNavigationBtnFn();
    }, [connection]);

  
    useEffect(() => {
        async function fetchData() {
            try {
                const queryString = window.location.search;
                const params = new URLSearchParams(queryString);
                let _storedSalaUser = params.get('isSalaUser') ?? localStorage.getItem(NAVIGATION_KEY).split('=')[1];
                let _storedIsMeetingSala = localStorage.getItem('isMeetingSala');
                const isSalaUser = _storedSalaUser ? _storedSalaUser === "true" : _storedIsMeetingSala === "true";
                const getMeetingsUrl = isSalaUser ? HubEndpoint.GetRiunioniSala : HubEndpoint.GetRiunioniPersonali;
                await connection.invoke(getMeetingsUrl).then((response) => {
                    const meetingList = response.eventi.map(x =>{
                        return new Meeting(
                            x.onlineMeeting?.joinUrl,
                            x.id,
                            x.start.dateTime,
                            x.end.dateTime,
                            x.subject
                        )}
                    )
                    setMeetings(meetingList);
                    setUserName(response.meetingSubject);
                    dispatch(hideLoader());
                });
            } catch (error) {
                console.log('Errore durante l\'invocazione SignalR:', error);
                dispatch(notificationOpen({ message: Messaggi.ComunicazioneServerAssente, status: NotificationStatus.Error }));
                dispatch(hideLoader());
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        setDisableStartMeetingBtn((!!selectedMeeting && selectedMeeting != '') ? false : true)
    }, [selectedMeeting]);

    const startMeeting = async () => {
        if (!selectedMeeting) return;
        if (isRunningInElectron()) {
            dispatch(openDialogMobileForOpenMeeting({
                dialogType: "openMeeting",
                isEdit: false,
                message: 'Desideri avviare il meeting anche sul tuo pc?',
                joinUrlMeeting: selectedMeeting
            }))
            dispatch(showLoader());
        }
        try {
            await connection.invoke(HubEndpoint.AvviaRiunione, selectedMeeting);
            navigate('/' + InternalAppRoutes[4]);
        } catch (error) {
            console.log('Errore durante l\'invocazione SignalR:', error);
            dispatch(notificationOpen({ message: Messaggi.ComunicazioneServerAssente, status: NotificationStatus.Error }));
            dispatch(hideLoader());
        }
    }

    const goBack = async () => {
        if (!disableNavigationBtn) {
            dispatch(showLoader());
            navigate('/' + InternalAppRoutes[2]);
        }
    }

    const disableNavigationBtnFn = (isDisconnected) => {
        setDisableNavigationBtn(isDisconnected);
        if (!isDisconnected) return
        dispatch(notificationOpen({ message: 'Connessione persa. Attendere il ripristino', status: NotificationStatus.Error }));

    }

    const handleOnlineStatusChange = (value) => {
        disableNavigationBtnFn(value);
    };

    connection.onreconnecting(connectionId => {
        handleOnlineStatusChange(true);
    });

    connection.onreconnected(connectionId => {
        handleOnlineStatusChange(false);
    });

    function getDataFormattata() {
        const oggi = new Date();
        const formattatore = new Intl.DateTimeFormat('it-IT', {
            day: '2-digit',
            month: '2-digit'
        });

        const dataFormattata = formattatore.format(oggi);
        return dataFormattata;
    }


    return (
        <>
            <div className="w-100  d-flex flex-column meetingList">
                <Row className="m-0 mt-4 px-4 d-flex justify-content-center" >
                    <Col lg={3} md={3} sm={3} className={`${isRunningInElectron() ? "w-auto" : ""}  px-0`}>
                        <img src={logoTeams} alt='' download="" width="100" className="" />
                    </Col>
                    <Col className="d-flex align-items-center mx-4 px-0 col-6" >
                        <h3 className="title bluetext_elencomeeting" >Elenco dei meeting <span className="bluetext_nomeSala_nomeAccount d-flex">{userName}</span></h3>
                    </Col>
                </Row>
                {isRunningInElectron() &&
                    <>
                        <Row className="m-0 mt-1 px-4" >
                            <Col lg={3} md={3} sm={3} className="px-0 d-flex justify-content-center">
                                <div className={`meeting-option-today today w-40`} >
                                    <span className="boldBlueText ">Oggi {getDataFormattata()}</span>
                                </div>
                            </Col>

                        </Row>
                    </>
                }
                {meetings && meetings.length > 0 &&
                    (<>
                        <Row className="w-100 m-0 mt-4 px-2 d-flex space-around list">
                            <Col className="p-0 d-flex justify-content-center">
                                <Grid item xs={12} sm={12} md={12} lg={12} className="w-100 px-2">
                                    <FormControl style={{ width: '100%' }} >
                                        <RadioGroup style={{ maxHeight: isRunningInElectron() || isNavigatingFromNotebook() ? '40dvh' : '50dvh', minHeight: isRunningInElectron() || isNavigatingFromNotebook() ? '40dvh' : '50dvh', overflowY: 'scroll', display: 'flex', flexWrap: 'nowrap', flexDirection: 'column' }} defaultValue="" name="meetings-group" value={selectedMeeting} onChange={(event) => setSelectedMeeting(event.target.value)}>
                                            {meetings.map((meeting) =>
                                                <MeetingOption
                                                    onSelect={(value) => setSelectedMeeting(value)}
                                                    key={meeting.id}
                                                    isSelected={selectedMeeting === meeting.url}
                                                    isPastEvent={meeting.isEventoPassato}
                                                    meeting={meeting}
                                                />
                                            )}
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-center w-100 mt-5 m-0 ">
                            <Col className="d-flex justify-content-center p-0 " xs={6} md={6} lg={6}>
                                <CustomButton classText="btn-text-size" size="" text="Indietro" className={'w-70'} onClickFn={() => disableNavigationBtn ? {} : goBack()}></CustomButton>
                            </Col>
                            {meetings && meetings.length > 0 &&
                                <Col className="d-flex justify-content-center p-0 " xs={6} md={6} lg={6}>
                                    <CustomButton classText="btn-text-size" size="" className={'w-70'} disabled={disableStartMeetingBtn} text="Avvia" onClickFn={() => startMeeting()}></CustomButton>
                                </Col>
                            }
                        </Row></>)
                }
                {meetings && meetings.length === 0 &&
                    <Grid container item xs={12} mt={2} ml={2} mb={2} className="centerVisualization mt-4" style={{ height: '100vh' }}>
                        <Typography variant="h5" className="boldBlueText" sx={{ fontSize: '1.4rem', textAlign: 'center' }}>Non ci sono Meeting in programma per oggi.</Typography>
                        <Grid container item xs={12} spacing={2} className="spaceAroundVisualization goBackStartbtn" style={{
                            marginTop: meetings && meetings.length === 0 ? '5rem' : '1rem',
                            display: 'flex',
                            position: 'fixed',
                            bottom: '1.5rem',
                            justifyContent: isNavigatingFromNotebook() ? 'center' : 'space-evenly',
                            width: isNavigatingFromNotebook() ? '50%' : ''
                        }}>
                            <Row className="d-flex justify-content-center w-100 mt-5 m-0 ">
                                <Col className="d-flex justify-content-center p-0 " xs={12} md={12} lg={12}>
                                    <CustomButton classText="btn-text-size" size="" text="Indietro" className={'w-40'} onClickFn={() => goBack()}></CustomButton>
                                </Col>
                            </Row>
                        </Grid>
                    </Grid>
                }


            </div>
            <Notification></Notification>
        </>
    )
}

export default TeamsSelectMeeting;