import { notificationOpen } from "../../reducers/notification-reducer";
import { NotificationStatus, Messaggi, HubEndpoint, OperazioneSceltaMobile, InternalAppRoutes } from "../../consts/dictionary";
import { showLoader, hideLoader} from "../../reducers/appLoader-reducer";
export class HomepageService {

    constructor(connection, navigate, dispatch) {
        this.connection = connection;
        this.navigate = navigate;
        this.dispatch = dispatch;
    }

    async accessTeams() {
        this.dispatch(showLoader());
        try {
            await this.connection.invoke(HubEndpoint.SendMessageToSala, OperazioneSceltaMobile.VisualizzaTeams).then((response) => {
                if (response.ok) this.navigate('/' + InternalAppRoutes[1]);
                else this.dispatch(notificationOpen({ message: response.message, status: NotificationStatus.Error }));
            });
        } catch (error) {
            console.log('Errore durante l\'invocazione SignalR:', error);
            this.dispatch(notificationOpen({ message: Messaggi.ComunicazioneServerAssente, status: NotificationStatus.Error }));
            this.dispatch(hideLoader());
        }
    }

    async abbandona() {
        this.dispatch(showLoader());
        try {
            await this.connection.invoke('SendMessageFromMobileToSala', OperazioneSceltaMobile.Abbandona).then((response) => {
                if (response.ok) this.navigate('/sessioneterminata');
                else this.dispatch(notificationOpen({ message: response.message, status: NotificationStatus.Error }));
            });
        } catch (error) {
            console.log('Errore durante l\'invocazione SignalR:', error);
            this.dispatch(notificationOpen({ message: Messaggi.ComunicazioneServerAssente, status: NotificationStatus.Error }));
            this.dispatch(hideLoader());
        }
    }

    async visualizzaTeletabber() {
        this.dispatch(showLoader());
        try {
            await this.connection.invoke(HubEndpoint.SendMessageToSala, OperazioneSceltaMobile.VisualizzaTeletabber).then((response) => {
                if (response.ok) this.navigate('/teletabber');
                else this.dispatch(notificationOpen({ message: response.message, status: NotificationStatus.Error }));
            });
        } catch (error) {
            console.log('Errore durante l\'invocazione SignalR:', error);
            this.dispatch(notificationOpen({ message: Messaggi.ComunicazioneServerAssente, status: NotificationStatus.Error }));
            this.dispatch(hideLoader());
        }
    }

    async visualizzaGuida() {
        this.dispatch(showLoader());
        try {
            await this.connection.invoke(HubEndpoint.SendMessageToSala, OperazioneSceltaMobile.VisualizzaGuida).then((response) => {
                if (response.ok) this.navigate('/guida');
                else this.dispatch(notificationOpen({ message: response.message, status: NotificationStatus.Error }));
            });
        } catch (error) {
            console.log('Errore durante l\'invocazione SignalR:', error);
            this.dispatch(notificationOpen({ message: Messaggi.ComunicazioneServerAssente, status: NotificationStatus.Error }));
            this.dispatch(hideLoader());
        }
    }
}