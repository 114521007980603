import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import "../../index.css"
import isNavigatingFromNotebook from '../functions/isNavigatingFromNotebook';
const CustomButton = ({ className, text, styles, size, onClickFn , disabled, classText}) => {
    const [_class, setClass] = useState(`${isNavigatingFromNotebook() && !className ? '' : ''} bg_blue_cl_white`);

    return (
        <>
            <Button disabled={disabled} className={className ? className + " " +  _class : _class} styles={styles} onClick={() => {
                
                onClickFn();
            }
            } variant="secondary" size={size}>
                 <span className={classText}>{text}</span>
            </Button>
        </>
    )
}

export default CustomButton;