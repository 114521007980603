import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const userSlice = createSlice({
    name:'userInfo',
    initialState,
    reducers: {
        saveUser: (state, payload) => {
            return payload.payload
         }
    }
});

export const { saveUser } = userSlice.actions;

const { reducer } = userSlice;

export default reducer;