import { createSlice } from '@reduxjs/toolkit';

const initialState = { renderPermission: "", isSalaOccupata: "", salaHasSlide: "" };

const permissionSlice = createSlice({
    name: 'permissionState',
    initialState,
    reducers: {
        enableRenderPermission: (state, payload) => {
            return { ...state, renderPermission: true, isSalaOccupata: true }
        },
        disableRenderPermission: (state, payload) => {
            return { ...state, renderPermission: false, isSalaOccupata: false }
        },
        enableGuida: (state, payload) => {
            return { ...state, salaHasSlide: true }
        },
        disableGuida: (state, payload) => {
            return { ...state, salaHasSlide: false }
        },
    }
});

export const { enableRenderPermission, disableRenderPermission, enableGuida, disableGuida } = permissionSlice.actions;

const { reducer } = permissionSlice;

export default reducer;