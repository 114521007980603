import { Grid } from '@mui/material'
import { useEffect, useContext, useState } from 'react'
import { useNavigate } from "react-router-dom"
import { HubEndpoint, Messaggi, NotificationStatus, OperazioneSceltaMobile } from '../../../consts/dictionary'
import { showLoader, hideLoader } from "../../../reducers/appLoader-reducer"
import ConnectionContext from "../../../context/ConnectionContext"
import logoTeams from '../../../assets/Microsoft_Teams_Accedi.png'
import buttonBackToMenu from '../../../assets/Torna_al_menu.png'
import { useDispatch, useSelector } from 'react-redux'
import { notificationOpen } from '../../../reducers/notification-reducer'
import Notification from '../../../components/Notification';
import { ID_SALA_KEY, IS_UTENTE_INAIL_KEY, QRCODE_TOKEN_KEY } from '../../../store/storage';
import AppSettingContext from '../../../context/AppSettingsContext'
import '../../../index.css';
import { TeamAccessService } from './teamaccess.service'
const TeamsAccessMobile = ({ windowReference, popupBlocked }) => {
    //HOOK
    const navigate = useNavigate();
    const state = useSelector((state) => state.notification);
    const dispatch = useDispatch();
    const connection = useContext(ConnectionContext);
    const appSetting = useContext(AppSettingContext);
    //STATE
    const [doPolling, setDoPolling] = useState(true);
    const [loggedUser, setLoggedUser] = useState();
    const [route, setRoute] = useState();
    const [connectionId, setConnectionId] = useState();
    const [isBlocked, setIsBlocked] = useState();
    //FUNCTIONS
    const backButtonLogic = (oldstate) => { window.history.pushState(oldstate, ""); };

    const service = new TeamAccessService(
        true, setIsBlocked,
        connection, navigate, dispatch,
        windowReference, appSetting, connectionId, setRoute, setLoggedUser, doPolling, setDoPolling);


    const goBack = async () => {
        dispatch(showLoader());
        let isUtenteInail = localStorage.getItem(IS_UTENTE_INAIL_KEY);
        let idSala = localStorage.getItem(ID_SALA_KEY);
        let qrCodeToken = localStorage.getItem(QRCODE_TOKEN_KEY);
        try {
            if(connection._connectionState !== 'Connected') { await connection.start()}
            await goBackToMenu()
        } catch (error) {
            if(!!windowReference && (windowReference.closed || connection._connectionState !== 'Connected')) {
                connection.start();
                dispatch(hideLoader());
                await goBackToMenu();
                return;
            }
            console.log('Errore durante l\'invocazione SignalR:', error);
            dispatch(notificationOpen({ message: Messaggi.ComunicazioneServerAssente, status: NotificationStatus.Error }));
            dispatch(hideLoader());
        }

        async function goBackToMenu() {
            await connection.invoke(HubEndpoint.SendMessageToSala, OperazioneSceltaMobile.VisualizzaMenu).then((response) => {
                if (response.ok) {
                    navigate('/?idSala=' + idSala + '&isUtenteInail=' + isUtenteInail + '&qrCodeToken=' + qrCodeToken)
                } else {
                    dispatch(notificationOpen({ message: response.message, status: NotificationStatus.Error }))
                }
            })
        }
    };

    //USEEFFECT
    useEffect(() => {
        let oldstate = window.history.state;
        window.history.pushState(oldstate, "");
        window.onpopstate = (event) => {
            if (event) backButtonLogic(oldstate);
        }
    }, []);

    useEffect(() => {
        if (windowReference) windowReference.close();
        setConnectionId(connection.connectionId);
        try {
            connection.invoke(HubEndpoint.SendMessageToSala, OperazioneSceltaMobile.VisualizzaTeams).then((response) => {
                if (!response.ok) dispatch(notificationOpen({ message: response.message, status: NotificationStatus.Error }));
            });
        } catch (error) {
            console.log('Errore durante l\'invocazione SignalR:', error);
            dispatch(notificationOpen({ message: Messaggi.ComunicazioneServerAssente, status: NotificationStatus.Error }));
            dispatch(hideLoader());
        }
        dispatch(hideLoader());
    }, []);

    useEffect(() => {
        if(!windowReference) return;
        if(windowReference.closed && connection._connectionState !== 'Connected') connection.start();
    }, [])




    const BlockedPopupText = () => {
            return (
                <Grid container item xs={12} spacing={2} className="popup-blocked-notification">
                    <Grid mx={5} item xs={12} sm={8} md={6} lg={5}>
                        <div style={{fontFamily:"Sogoe UI" , fontSize:'medium'}}>
                            Per garantire un'esperienza completa, ti preghiamo di sbloccare i popup e i reindirizzamenti sul tuo dispositivo. Basta aprire le impostazioni del browser, consentire popup e reindirizzamenti.
                        </div>
                    </Grid>
                </Grid>
            );
    };

    return (
        <>
            <Grid container className='container-home'>
                <Grid container item xs={12} spacing={2} className="spaceAroundVisualization" style={{display:'flex', justifyContent:'center'}}>
                    <Grid  item xs={12} sm={12} md={6} mt={4}  className="centerVisualizationLogo">
                        <img src={logoTeams} download="" alt='' className="scaleout" onClick={async () => await service.accessTeams()} />
                    </Grid>
                </Grid>
                {isBlocked && <BlockedPopupText />}
                <Grid container item xs={12} spacing={2} className="spaceAroundVisualization btn-cst-home" style={{display:'flex', justifyContent:'center'}}>
                    <Grid item xs={12} sm={12} md={6} mt={3} >
                        <img src={buttonBackToMenu} download="" alt='' className="imageAutoSize  imageAutoSizeBtnAccessGoBack" onClick={() => goBack()} />
                    </Grid>
                </Grid>
            </Grid >
            <Notification></Notification>
        </>
    )
}

export default TeamsAccessMobile;