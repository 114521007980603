import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../reducers/userData-reducer';
import salaReducer from '../reducers/salaData-reducer';
import notificationReducer from '../reducers/notification-reducer';
import appLoaderReducer from '../reducers/appLoader-reducer';
import dialogMobileReducer from "../reducers/dialogMobile-reducer";
import permissionReducer from "../reducers/permission-reducer";

const store = configureStore({
    reducer: {
        userData: userReducer,
        salaData: salaReducer,
        notification: notificationReducer,
        appLoader: appLoaderReducer,
        dialogMobile: dialogMobileReducer,
        permissionReducer: permissionReducer
    }
});

export default store; 