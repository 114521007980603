import * as yup from "yup";

export const validationSchemaRecuperoSessioneAdd = yup.object({
    reconnectedCode: yup.number().required("Campo obbligatorio.").test('is-four-digits', 'Per favore inserire un PIN di 4 cifre.', value => {
        return value && value.toString().length === 4;
    }),
});


export const validationSchemaAccessPin = yup.object({
    accessPin: yup.number().required("Campo obbligatorio.").test('', 'Per favore inserire un PIN.', value => {
        return value;
    }),
});