export function initialValuesRecuperoSessioneAdd() {
  return {
    reconnectedCode: ""
  }
};

export function initialValuesPinAccess() {
  return {
    accessPin: ""
  }
};