import { useContext, useEffect, useState } from "react";
import { Grid, Box, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { InternalAppRoutes, HubEndpoint, ComandiTeams, Messaggi, NotificationStatus, NAVIGATION_KEY } from '../../../consts/dictionary';
import { showLoader, hideLoader } from "../../../reducers/appLoader-reducer";
import ConnectionContext from "../../../context/ConnectionContext";
import logoTeams from '../../../assets/Microsoft_Office_Teams_noBorder.png';
import buttonCloseCall from '../../../assets/Chiamata_chiudi.png';
import buttonRaiseHand from '../../../assets/Mano.png';
import buttonMicrophone from '../../../assets/Mic.png';
import buttonVideo from '../../../assets/Video.png';
import "../../../index.css";
import { useDispatch, useSelector } from 'react-redux';
import { notificationOpen } from '../../../reducers/notification-reducer';
import Notification from '../../../components/Notification';
import { Col, Row } from "react-bootstrap";

const TeamsTelecomando = () => {
    const navigate = useNavigate();
    const state = useSelector((state) => state.notification);
    const dispatch = useDispatch();
    const connection = useContext(ConnectionContext);

    const backButtonLogic = (oldstate) => { window.history.pushState(oldstate, ""); };
    const [commandSentMessageAbbandonaSessione, setCommandSentMessageAbbandonaSessione] = useState("");
    const [commandSentMessageAttivaDisattivaMicrofono, setCommandSentMessageAttivaDisattivaMicrofono] = useState("");
    const [commandSentMessageAttivaDisattivaVideocamera, setCommandSentMessageAttivaDisattivaVideocamera] = useState("");
    const [commandSentMessageAlzaAbbassaMano, setCommandSentMessageAlzaAbbassaMano] = useState("");
    const [isButtonActive, setIsButtonActive] = useState(false);

    useEffect(() => {
        dispatch(showLoader());
        let oldstate = window.history.state;
        window.history.pushState(oldstate, "");
        window.onpopstate = (event) => {
            if (event) backButtonLogic(oldstate);
        }
        setTimeout(() => { dispatch(hideLoader()); }, 5000)
    }, []);

    const sendCommand = async (command) => {
        try {
            const comandoIviatoText = "Comando inviato"
            await connection.invoke(HubEndpoint.SendComandoTeams, command);
            setIsButtonActive(true);
            switch (command) {
                case ComandiTeams.AttivaDisattivaVideocamera:
                    setCommandSentMessageAttivaDisattivaVideocamera(comandoIviatoText);
                    break;
                case ComandiTeams.AttivaDisattivaMicrofono:
                    setCommandSentMessageAttivaDisattivaMicrofono(comandoIviatoText);
                    break;
                case ComandiTeams.AlzaAbbassaMano:
                    setCommandSentMessageAlzaAbbassaMano(comandoIviatoText);
                    break;
                case ComandiTeams.AbbandonaRiunione:
                    setCommandSentMessageAbbandonaSessione(comandoIviatoText);
                    break;

            }
            setTimeout(() => {
                setCommandSentMessageAttivaDisattivaVideocamera("")
                setCommandSentMessageAttivaDisattivaMicrofono("")
                setCommandSentMessageAlzaAbbassaMano("")
                setCommandSentMessageAbbandonaSessione("");
                setIsButtonActive(false);

            }, 2000);
            if (command === ComandiTeams.AbbandonaRiunione) {
                dispatch(showLoader());
                navigate(`/${InternalAppRoutes[3]}?isSalaUser=${localStorage.getItem(NAVIGATION_KEY).endsWith('isSalaUser=false') ? "false" : "true"}`);
            }
        } catch (error) {
            console.log('Errore durante l\'invocazione SignalR:', error);
            dispatch(notificationOpen({ message: Messaggi.ComunicazioneServerAssente, status: NotificationStatus.Error }));
            dispatch(hideLoader());
        }
    }


    return (
        <>
            <Row>
                <Col xs={12} md={12} lg={12} className="px-0 d-flex justify-content-center">
                    <img src={logoTeams} alt='' download="" className="" />
                </Col>
            </Row>
            <Row className="w-100 mt-5 d-flex justify-content-evenly">
                <Col xs={6} md={6} lg={6} className={`buttonTelecomando ${isButtonActive ? 'active' : ''} px-0 tasto_note`} onClick={() => sendCommand(ComandiTeams.AttivaDisattivaVideocamera)}>
                    <img src={buttonVideo} alt='' download="" className="imageScaled" />
                    <Typography className="comadoInviatoMessage_note">{commandSentMessageAttivaDisattivaVideocamera}</Typography>
                </Col>
                <Col xs={6} md={6} lg={6} className={`buttonTelecomando ${isButtonActive ? 'active' : ''} buttonTelecomandoLess tasto_note` } onClick={() => sendCommand(ComandiTeams.AttivaDisattivaMicrofono)}>
                    <img src={buttonMicrophone} alt='' download="" className="imageLessScaled" />
                    <Typography className="comadoInviatoMessage_note">{commandSentMessageAttivaDisattivaMicrofono}</Typography>
                </Col>

            </Row>
            <Row className="w-100 d-flex justify-content-evenly mt-5">
                <Col xs={6} md={6} lg={6}  className={`buttonTelecomando ${isButtonActive ? 'active' : ''} buttonTelecomandoMano px-0 tasto_note`} onClick={() => sendCommand(ComandiTeams.AlzaAbbassaMano)}>
                    <img src={buttonRaiseHand} alt='' download="" className="imageScaled" />
                    <Typography className="comadoInviatoMessage_note">{commandSentMessageAlzaAbbassaMano}</Typography>
                </Col>
                <Col xs={6} md={6} lg={6} className={`buttonTelecomando ${isButtonActive ? 'active' : ''} buttonTelecomandoAbbandona px-0 tasto_note`} onClick={() => sendCommand(ComandiTeams.AbbandonaRiunione)}>
                    <img src={buttonCloseCall} alt='' download="" className="imageScaled" />
                    <Typography className="comadoInviatoMessage_note">{commandSentMessageAbbandonaSessione}</Typography>

                </Col>
            </Row>
            <Notification></Notification>
        </>
    )
}

export default TeamsTelecomando;

{/* <Grid container style={{height:'80%'}}>
<Grid container item xs={12} spacing={2} mt={2} className="centerVisualization teamslogo">
    <img src={logoTeams} alt='' download="" className="imageQuarterWidth" />
</Grid>
<Grid container item mt={2} rowSpacing={6} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
    <Grid item xs={6} className="centerVisualization tasto">
        <Box className={`buttonTelecomando ${isButtonActive ? 'active' : ''}`} onClick={() => sendCommand(ComandiTeams.AttivaDisattivaVideocamera)}>
            <img src={buttonVideo} alt='' download="" className="imageScaled" />
        </Box>
        <Typography className="comandoInviatoMessage">{commandSentMessageAttivaDisattivaVideocamera}</Typography>
    </Grid>
    <Grid item xs={6} className="centerVisualization tasto">
        <Box className={`buttonTelecomando ${isButtonActive ? 'active' : ''} buttonTelecomandoLess` } onClick={() => sendCommand(ComandiTeams.AttivaDisattivaMicrofono)}>
            <img src={buttonMicrophone} alt='' download="" className="imageLessScaled" />
        </Box>
        <Typography className="comandoInviatoMessage">{commandSentMessageAttivaDisattivaMicrofono}</Typography>
    </Grid>
    <Grid item xs={6} className="centerVisualization tasto">
        <Box className={`buttonTelecomando ${isButtonActive ? 'active' : ''} buttonTelecomandoMano`} onClick={() => sendCommand(ComandiTeams.AlzaAbbassaMano)}>
            <img src={buttonRaiseHand} alt='' download="" className="imageScaled" />
        </Box>
        <Typography className="comandoInviatoMessage">{commandSentMessageAlzaAbbassaMano}</Typography>
    </Grid>
    <Grid item xs={6} className="centerVisualization tasto">
        <Box className={`buttonTelecomando ${isButtonActive ? 'active' : ''} buttonTelecomandoAbbandona`} onClick={() => sendCommand(ComandiTeams.AbbandonaRiunione)}>
            <img src={buttonCloseCall} alt='' download="" className="imageScaled" />
        </Box>
        <Typography className="comandoInviatoMessage">{commandSentMessageAbbandonaSessione}</Typography>
    </Grid>
</Grid>
</Grid > */}