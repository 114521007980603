import { FormControlLabel, Radio, Typography } from '@mui/material';
import moment from 'moment';
import "../index.css";
import { useState } from 'react';
import isNavigatingFromNotebook from '../shared/functions/isNavigatingFromNotebook';
import { isRunningInElectron } from '../shared/functions/isRanningElectron';

const MeetingOption = ({meeting, onSelect, isSelected, isPastEvent}) => {

    const handleSelect = (urlMeeting) => {
        onSelect(urlMeeting)
    };
    return(
        <>
            <FormControlLabel 
            sx={{marginTop:'0.8rem', marginLeft:'0px', marginRight:'0px'}} 
            className={isNavigatingFromNotebook() || isRunningInElectron() ? ``: 'label-cst-margin'} value={meeting.url} control={
                <div   className={`meeting-option ${isSelected ? 'selected' : ''} ellipsisTypo`} style={{width:'100%',     padding:' 12px 10px 12px 10px;'}}  onClick={() => handleSelect(meeting.url)}>
                    <Typography className={`${!isPastEvent && !isSelected ? 'titlegray' : '' }  boldBlueText ellipsisTypo cst-font-size` } sx={{fontSize: '1rem', marginLeft:'0.1rem'}}>{meeting.subject}</Typography>
                    <Typography className={`${!isPastEvent && !isSelected ? 'titlegray' : '' } blueText cst-font-size`} sx={{fontSize: '0.75rem', lineHeight:'0.9', marginLeft:'0.1rem'}}>{moment(meeting.start).format('HH:mm')} - {moment(meeting.end).format('HH:mm')}</Typography>
                </div>
                }
            />
        </>
    );
}

export default MeetingOption;
