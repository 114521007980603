import { createSlice } from '@reduxjs/toolkit';

const initialState = {isOpen:false , isEdit: true, dialogType:"", dialogPageType:"", message:"", reconnectedCode:""};

const mobileSlice = createSlice({
    name:'dialogMobile',
    initialState,
    reducers: {
         openDialogMobile:(state,payload) => {
             return {...state, isOpen:true, dialogType:payload.payload.dialogType, isEdit:payload.payload.isEdit, message:payload.payload.message, reconnectedCode: payload.payload.reconnectedCode}
         },
         closeDialogMobile: (state) => {
            return {...state, isOpen: false};
         },

         openDialogMobileForOpenMeeting:(state,payload) => {
            return {...state, isOpen:true, dialogType:payload.payload.dialogType, isEdit:payload.payload.isEdit, message:payload.payload.message, joinUrlMeeting:payload.payload.joinUrlMeeting }
        },
        openDialogMobileForMutedAudio:(state,payload) => {
            return {...state, isOpen:true, dialogType:payload.payload.dialogType, isEdit:payload.payload.isEdit, message:payload.payload.message, joinUrlMeeting:payload.payload.joinUrlMeeting }
        },
    }
});

export const { openDialogMobile, closeDialogMobile , openDialogMobileForOpenMeeting, openDialogMobileForMutedAudio} = mobileSlice.actions;

const { reducer } = mobileSlice;

export default reducer;