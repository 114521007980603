import { Typography } from '@mui/material';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logoTeams from '../../../assets/Microsoft_Office_Teams_noBorder.png';
import { apiMIP, InternalAppRoutes, NotificationStatus, NAVIGATION_KEY } from '../../../consts/dictionary';
import ConnectionContext from "../../../context/ConnectionContext";
import AppSettingContext from "../../../context/AppSettingsContext";
import "../../../index.css"
import { hideLoader } from "../../../reducers/appLoader-reducer";
import { notificationOpen } from '../../../reducers/notification-reducer';
import { Row, Col } from 'react-bootstrap';
import { TeamsSelectAccountService } from './teamsselectaccount.service';
import CustomButton from '../../../shared/components/CustomButton';
import isNavigatingFromNotebook from '../../../shared/functions/isNavigatingFromNotebook';
const TeamsSelectAccount = ({ windowReference, setWindowReference, logoutMicrosoftUrl, canLogoutOnNavigationBack, enablelogout }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.userData);
    const salaData = useSelector((state) => state.salaData);
    const connection = useContext(ConnectionContext);
    const appSetting = useContext(AppSettingContext);
    const [disableNavigationBtn, setDisableNavigationBtn] = useState();
    const { givenName, surname, mail } = userData;
    let connectionId = connection.connection.connectionId;
    const service = new TeamsSelectAccountService(navigate, dispatch, logoutMicrosoftUrl, connectionId, setWindowReference, disableNavigationBtn);
    //USEEFFECT
    useEffect(() => {
        let oldstate = window.history.state;
        window.history.pushState(oldstate, "");
        window.onpopstate = (event) => {
            if (event) backButtonLogic(oldstate);
        }
    }, []);

    useEffect(() => {
        if (windowReference) windowReference.close();
        dispatch(hideLoader());
    }, []);



    //FUNCTIONS
    const backButtonLogic = (oldstate) => { window.history.pushState(oldstate, ""); };


    const goBack = async () => {
        if (canLogoutOnNavigationBack) {
            navigate('/' + InternalAppRoutes[1]);
            axios.get(`${appSetting.URL_API}/${apiMIP.logout}?connectionId=${connectionId}`).then(response => {
                
            }).catch(error => { console.error('Error:', error); });
            return;
        }
        let logoutUrl = logoutMicrosoftUrl + connectionId;
        let logoutWindowReference = window.open(logoutUrl);
        setWindowReference(logoutWindowReference);
    }

    const disableNavigationBtnFn = (isDisconnected) => {
        setDisableNavigationBtn(isDisconnected);
        if (!isDisconnected) return
        dispatch(notificationOpen({ message: 'Connessione persa. Attendere il ripristino', status: NotificationStatus.Error }));

    }

    const handleOnlineStatusChange = (value) => { disableNavigationBtnFn(value); };

    connection.onreconnecting(connectionId => { handleOnlineStatusChange(true); });

    connection.onreconnected(connectionId => { handleOnlineStatusChange(false); });

    return (
        <>
            <Row className="m-0">
                <Col className="d-flex justify-content-center">
                    <img src={logoTeams} alt='' download="" className='imageAutoSize' />
                </Col>
            </Row>
            <Row className="m-0">
                <Col className="d-flex justify-content-center">
                    <h5 className="boldBlueText" style={{fontSize:'2.25rem'}}>Scegli l'account</h5>
                </Col>
            </Row>
            <Row className="m-0 min-w-50 mt-3 ">
                <Col className="d-flex justify-content-center" onClick={() => disableNavigationBtn ? {} : service.getUserMeetings()}>
                    <p className="boldWhiteText text-center w-100 py-1 accountName hovered bg-blue" >{givenName} {surname}</p>
                </Col>
            </Row>
            <Row className="m-0 min-w-50 mt-3">
                <Col className="d-flex justify-content-center" onClick={() => disableNavigationBtn ? {} : service.getSalaMeetings()}>
                    <p className="boldWhiteText text-center w-100 py-1 accountName hovered bg-blue">{salaData}</p>
                </Col>
            </Row>
            <Row className={`${!enablelogout ? 'd-flex justify-content-center' : ''} w-100 mt-5 mx-0`}>
                <Col className="d-flex justify-content-center p-0" xs={6} md={6} lg={6}>
                    <CustomButton classText="btn-text-size" size="" text="Menu" className={isNavigatingFromNotebook() ?'w-50' : 'w-70'} onClickFn={() => goBack()}></CustomButton>
                </Col>
                {enablelogout &&
                    <Col className="d-flex justify-content-center p-0 " xs={6} md={6} lg={6}>
                        <CustomButton classText="btn-text-size" size="" className={isNavigatingFromNotebook() ?'w-50' : 'w-70'} text="Logout" onClickFn={() => service.logout()}></CustomButton>
                    </Col>
                }
            </Row>
        </>
    )
}

export default TeamsSelectAccount;