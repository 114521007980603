import { notificationOpen } from "../../../reducers/notification-reducer";
import { HubEndpoint, Messaggi, OperazioneSceltaMobile, NotificationStatus, InternalAppRoutes } from "../../../consts/dictionary";
import sleep from "../../../shared/functions/sleep";
import { saveUser } from "../../../reducers/userData-reducer";
import axios from "axios";
import { showLoader, hideLoader } from "../../../reducers/appLoader-reducer";
import { QRCODE_TOKEN_KEY } from "../../../store/storage";
export class TeamAccessService {

    constructor(
        isFromMobile,
        setIsBlocked,
        connection, 
        navigate, 
        dispatch, 
        windowReference, 
        appSetting, 
        connectionId,
        setRoute,
        setLoggedUser,
        doPolling,
        setDoPolling
    ) {
        this.isFromMobile = isFromMobile;
        this.setIsBlocked = setIsBlocked;
        this.connection = connection;
        this.connectionId= connectionId;
        this.navigate = navigate;
        this.dispatch = dispatch;
        this.appSetting = appSetting;
        this.windowReference = windowReference;
        this.setRoute = setRoute;
        this.setLoggedUser = setLoggedUser;
        this.setDoPolling = setDoPolling;
        this.doPolling = doPolling;
    }

    async accessTeams (){
        if (!!this.windowReference && !this.windowReference.closed ) { 
            this.dispatch(showLoader()); 
            if(this.isFromMobile && this.setIsBlocked){this.setIsBlocked(true);}
        }else if(this.isFromMobile){
            this.setIsBlocked(false);
        }
        try {
            if (this.connection._connectionState !== 'Connected') { await this.connection.start() }
            await this.loginMicrosoft();
        } catch (error) {
            if (!!this.windowReference && (this.windowReference.closed || this.connection._connectionState !== 'Connected')) {
                this.connection.start();
                await this.loginMicrosoft()
                return;
            }
            console.log('Errore durante l\'invocazione SignalR:', error);
            this.dispatch(notificationOpen({ message: Messaggi.ComunicazioneServerAssente, status: NotificationStatus.Error }));
            
        }
        this.dispatch(hideLoader());
    };

    async loginMicrosoft() {
        await this.connection.invoke(HubEndpoint.SendMessageToSala, OperazioneSceltaMobile.AccediTeams)
        await this.connection.invoke(HubEndpoint.OnClickAuthTeams)
        await this.connection.stop()
        await this.recursiveCheckAuthenticated()
        localStorage.setItem('isMicrosoftUserLogged', true)
        if(this.isFromMobile){
            this.setIsBlocked(false);
        }
    }

    async recursiveCheckAuthenticated() {
        try {
            if (!this.doPolling) return;
            await sleep(1000);
            const res = await this.checkAutenticated();
            if (!res.data.ok) throw new Error("");
            this.setRoute(res.data.result.route);
            this.setLoggedUser(res.data.result.loggedUser);
            this.setDoPolling(false);
            this.connection.connection.baseUrl = this.connection.connection.baseUrl.replace("undefined", localStorage.getItem(QRCODE_TOKEN_KEY))
            await this.connection.start();
            this.dispatch(saveUser(res.data.result.loggedUser));
            this.navigate('/' + InternalAppRoutes[2]);
        } catch (error) {
            await this.recursiveCheckAuthenticated();
        }

    };

   

    async checkAutenticated() { return await axios.get(`${this.appSetting.URL_API}/${this.appSetting.URL_CHECK_AUTHETICATED}?connectionId=${this.connectionId}`); };


}