import { NAVIGATION_KEY, InternalAppRoutes } from "../../../consts/dictionary";
import { showLoader } from "../../../reducers/appLoader-reducer";
export class TeamsSelectAccountService{

    constructor(navigate, dispatch, logoutMicrosoftUrl ,connectionId, setWindowReference ,disableNavigationBtn){
        this.navigate = navigate;
        this.dispatch = dispatch;
        this.logoutMicrosoftUrl = logoutMicrosoftUrl;
        this.disableNavigationBtn= disableNavigationBtn;
        this.connectionId = connectionId;
        this.setWindowReference = setWindowReference;
    }


    getUserMeetings(){
        localStorage.removeItem(NAVIGATION_KEY);
        this.dispatch(showLoader());
        if (!this.disableNavigationBtn) {
            let uri = `/${InternalAppRoutes[3]}?isSalaUser=false`;
            localStorage.setItem(NAVIGATION_KEY, uri);
            this.navigate(uri, { state: { from: window.location.pathname } });
        }
    }

    getSalaMeetings(){
        localStorage.removeItem(NAVIGATION_KEY);
        this.dispatch(showLoader());
        if (!this.disableNavigationBtn) {
            let uri = `/${InternalAppRoutes[3]}?isSalaUser=true`;
            localStorage.setItem(NAVIGATION_KEY, uri);
            this.navigate(uri, { state: { from: window.location.pathname } });
        }
    }

    logout(){
        let logoutUrl = this.logoutMicrosoftUrl + this.connectionId;
        let logoutWindowReference = window.open(logoutUrl);
        this.setWindowReference(logoutWindowReference);
    }
}