import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const RouteHistoryContext = createContext({});

export const RouteHistoryProvider = ({ children }) => {
    const [previousPath, setPreviousPath] = useState(null);
    const location = useLocation();

    useEffect(() => {
        setPreviousPath(location.pathname);
    }, [location]);

    return (
        <RouteHistoryContext.Provider value={{ previousPath }}>
            {children}
        </RouteHistoryContext.Provider>
    );
};

export const useRouteHistory = () => {
    const context = useContext(RouteHistoryContext);
    if (context === undefined) {
        throw new Error("useRouteHistory must be used within a RouteHistoryProvider");
      }
      return context;
};